import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  CssBaseline,
  Grid,
} from '@material-ui/core'

import Header from 'components/Header'
import Menu from 'components/Menu'
import Main from 'components/Main'

const Layout = () => {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <Header />
      <Grid container className={classes.container} direction='row' wrap='nowrap'>
        <Grid item className={classes.f00a}>
          <Menu />
        </Grid>
        <Grid item className={classes.f11a}>
          <Main />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
  f00a: {
    flex: '0 0 auto',
  },
  f11a: {
    flex: '1 1 auto',
  },
}))

export default Layout
