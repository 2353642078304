import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  Grid,
  Typography,
} from '@material-ui/core'

import UserTable from 'components/Tables/UserTable'
import { OidcSecure } from '@axa-fr/react-oidc-context'
import SearchPageHeader from './SearchPageHeader'

const UserSearchPage = () => {
  const classes = useStyles()
  const [muiTableKey, setMuiTableKey] = useState(0)

  return (
    <OidcSecure>
      <div className={classes.container}>
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='h4' className={classes.headline}>Nutzersuche</Typography>
            </Grid>
            <SearchPageHeader onReset={() => {
              setMuiTableKey(muiTableKey + 1)
            }}
            />
          </Grid>
          <UserTable
            muiTableKey={muiTableKey}
          />
        </div>
      </div>
    </OidcSecure>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: '1 1 auto',
  },
  content: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.raumo.palette.background.main,
    padding: theme.spacing(2),
    width: '100%',
  },
}))

export default UserSearchPage
