import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Route, Switch } from 'react-router-dom'
import UserSearchPage from './Pages/UserSearchPage'
import UserDetailsPage from './Pages/UserDetailsPage'
import LoginPage from './Pages/LoginPage'

/**
 * Component for main content. Routes go here
 */
const Main = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Switch>
        <Route path='/login' component={LoginPage} />
        <Route path='/usersearch'>
          <UserSearchPage />
        </Route>
        <Route path='/userdetails/:userId'>
          <UserDetailsPage />
        </Route>
        {/* all undefined routes go to login */}
        <Route path='*'>
          <LoginPage />
        </Route>
      </Switch>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.raumo.palette.background.main,
    padding: theme.spacing(4),
    width: '100%',
  },
}))

export default Main
