import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  Card,
  Grid,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useOidc } from '@axa-fr/react-oidc-context'

const LoginPage = () => {
  const classes = useStyles()
  const { login, isAuthenticated } = useOidc()

  const history = useHistory()
  const location = useLocation()

  // set redirect target from route or default to usersearch
  const { from } = location.state || { from: { pathname: '/usersearch' } }

  // redirects when user is logged in
  useEffect(() => {
    if (isAuthenticated) {
      history.replace(from)
    }
  }, [history, from, isAuthenticated])

  return (
    <Grid container className={classes.loginContainer} justify='center' alignItems='center'>
      <Grid item>
        <Card className={classes.loginCard} square>
          {!isAuthenticated &&
            <Button
              color='primary'
              fullWidth
              type='submit'
              variant='contained'
              onClick={() => { login() }}
            >
              Anmelden
            </Button>}
        </Card>
      </Grid>
    </Grid>

  )
}

const useStyles = makeStyles(theme => ({
  loginCard: {
    maxWidth: theme.raumo.layout.loginCard.maxWidth,
    padding: theme.spacing(4),
  },
  loginContainer: {
    height: '100%',
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
}))

export default LoginPage
