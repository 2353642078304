import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

/**
 *  This buttons triggers a request on click. As long as the request is not resolved,
 *  the button is disabled and a circular spinner is displayed.
 */
const RequestButton = props => {
  const {
    children,
    color,
    disabled,
    isLoading,
    onClick,
    ...buttonProps
  } = props
  const classes = useStyles()

  return (
    <Button
      {...buttonProps}
      classes={{ label: classes.buttonLabel }}
      color={color}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading && (
        <CircularProgress
          classes={{ root: classes.progressRoot, svg: classes.progressSvg }}
          color={color}
          size={24}
        />)}
      {children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  buttonLabel: {
    position: 'relative',
  },
  progressRoot: {
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'absolute',
  },
  progressSvg: {
    height: '100%',
  },
}))

RequestButton.defaultProps = {
  color: 'primary',
}

RequestButton.propTypes = {
  /** Content of the button */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** Color of button and spinner */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  /** If set, button is disabled independent of isLoading state */
  disabled: PropTypes.bool,
  /** Makes button display loading state */
  isLoading: PropTypes.bool,
  /** Click handler */
  onClick: PropTypes.func,
}

export default RequestButton
