import Swagger from 'swagger-client'
import logger from 'util/logger'
import document from 'util/init_swaggerFile.js'

/**
 *  generates LSD client from server side swagger spec
 */
export default class InitClientService {
  constructor (options = {}) {
    this._accessToken = null
    this.promise = Swagger({
      spec: document,
      requestInterceptor: req => {
        if (this._accessToken) {
          req.headers.Authorization = 'Bearer ' + this._accessToken
        }
        return req
      },
    }).then(client => {
      // overwrite spec basePath if given in options (for docker proxy)
      if (options.basePath) {
        client.spec.basePath = '/' + options.basePath
      }

      this.swaggerClient = client
      return client.apis
    })
      .catch(err => {
        logger.error('Error while creating initClient based on swaggerfile', err)
      })
  }

  /**
   *  resolves with api functions when swagger client is ready
   */
  ready () {
    return this.promise
  }

  setAccessToken (accessToken) {
    this._accessToken = accessToken
  }
}
