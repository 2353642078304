import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import {
  AppBar,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core'

import {
  PowerSettingsNew as PowerSettingsNewIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons'

import { ReactComponent as Logo } from 'assets/Logo.svg'
import { ReactComponent as LogoOrtenau } from 'assets/LogoOrtenau.svg'

import { useOidc, useOidcUser } from '@axa-fr/react-oidc-context'
import PasswordChangeDialog from './Dialog/PasswordChangeDialog'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

/**
 * Header, includes logo and toolbar
 */
const Header = () => {
  const classes = useStyles()
  const { logout, isAuthenticated } = useOidc()
  const { oidcUser } = useOidcUser()
  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] = useState(false)
  const open = Boolean(anchorEl)

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }

  const handlePasswordChangeDialog = () => {
    setPasswordChangeDialogOpen(true)
  }

  const handleClosePasswordChangeDialog = () => {
    setPasswordChangeDialogOpen(false)
  }

  return (
    <div>
      <AppBar
        className={clsx(classes.appbar, classes.f10a)}
        position='sticky'
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.f10a}>
            {process.env.REACT_APP_CLIENT_ID === 'ortenaumobil' ? (
              <LogoOrtenau className={classes.logo} />
            ) : (
              <Logo className={classes.logo} />
            )}
          </div>
          {isAuthenticated && (
            <>
              <Button
                classes={{
                  label: classes.accountLabel,
                }}
                color='inherit'
                startIcon={<AccountCircleIcon />}
                onClick={handleOpenUserMenu}
              >
                {`${oidcUser?.given_name} ${oidcUser?.family_name}`}
              </Button>
              <Menu
                id='menu-appbar'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={handlePasswordChangeDialog}
                >
                  <ListItemIcon>
                    <VpnKeyIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Passwort ändern' />
                </MenuItem>
                <MenuItem
                  onClick={() => { logout() }}
                >
                  <ListItemIcon>
                    <PowerSettingsNewIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Abmelden' />
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <PasswordChangeDialog
        open={passwordChangeDialogOpen}
        onClose={handleClosePasswordChangeDialog}
        userId={oidcUser?.sub}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  f10a: {
    flex: '1 0 auto',
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.mixins.toolbar.minHeight,
  },
  logo: {
    verticalAlign: 'middle',
  },
  accountLabel: {
    textTransform: 'capitalize',
  },
}))

export default Header
