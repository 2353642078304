import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import {
  Button,
  Grid,
} from '@material-ui/core'

import SearchUserDialog from 'components/Dialog/SearchUserDialog'

const SearchPageHeader = ({ onReset }) => {
  const classes = useStyles()
  const [open, setSearchDialogOpen] = useState(false)

  return (
    <>
      <SearchUserDialog
        open={open}
        onClose={() => { setSearchDialogOpen(false) }}
      />
      <Grid container item justify='flex-end' xs={6}>
        <Button
          color='primary'
          variant='outlined'
          className={classes.headline}
          onClick={() => {
            setSearchDialogOpen(true)
          }}
        >
          Nutzer über User ID aufrufen
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.headline}
          onClick={onReset}
        >
          Eingabe zurücksetzen
        </Button>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  headline: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

SearchPageHeader.propTypes = {
  /** Function close Dialog **/
  onReset: PropTypes.func,
}

export default SearchPageHeader
