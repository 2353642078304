
import React, { useCallback } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 *  a dialog that opens when verification email is sent
 */

const VerificationSentDialog = ({
  onCancel,
  open,
  error,
  success,
  setError,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    onCancel()
    setError(false)
  }

  const renderContent = useCallback(() => {
    if (error.length > 0) {
      return (
        <>
          <DialogTitle id='title' onClose={onCancel}>
            <Typography component='div' variant='h4'>Verifikations-Mail konnte nicht gesendet werden</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs>
              <Grid container item>
                <Grid container item direction='column' justify='flex-start' wrap='nowrap'>
                  <Typography variant='subtitle2' className={classes.error}>{error}</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>Für weitere Informationen wenden Sie sich bitte an den init-Support.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )
    } else if (success) {
      return (
        <>
          <DialogTitle id='title' onClose={onCancel}>
            <Typography component='div' variant='h4'>Verifikations-Mail erfolgreich versendet</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs>
              <Grid container item>
                <Grid container item direction='column' justify='flex-start' wrap='nowrap'>
                  <Typography variant='subtitle2' color='textSecondary'>Die Verifikations-Mail wurde erfolgreich versendet.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )
    } else {
      return (
        <div className={classes.centerSpinner}>
          <CircularProgress color='primary' />
        </div>
      )
    }
  }, [error, success, onCancel, classes.error, classes.centerSpinner])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='title'
      maxWidth='sm'
      fullWidth
    >
      <Grid className={classes.m2}>
        {renderContent()}
        <Divider variant='middle' className={classes.mb1} />
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
            <Typography variant='button' className={classes.button}>Schließen</Typography>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  centerSpinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  button: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  error: {
    color: 'rgba(244, 67, 54)',
    marginBottom: theme.spacing(2),
  },
}))

VerificationSentDialog.propTypes = {
  /** callback when dialog is cancelled */
  onCancel: PropTypes.func,
  /** callback when email is sent, the dialog is opened */
  open: PropTypes.bool,
  /** string that contains possible error message  */
  error: PropTypes.string,
  /** bool that indicates that seding of verification was successful */
  success: PropTypes.bool,
  /** sets the error state */
  setError: PropTypes.func,
}
export default VerificationSentDialog
