import React, { useCallback, useState } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  makeStyles,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

/**
 *  a dialog to enter a new email address
 */

const UpdateEmailDialog = ({
  onCancel,
  open,
  onSubmit,
  error,
  success,
  setError,
}) => {
  const validationSchema = Yup.object({
    EmailAddress: Yup
      .string()
      .email('Bitte überprüfen Sie die Eingabe.')
      .required('Bitte geben Sie eine Email ein.'),
  })

  const classes = useStyles()
  const [dialogState, setDialogState] = useState('form')

  const handleClose = () => {
    onCancel()
    setDialogState('form')
    setError(false)
  }

  const renderContent = useCallback((value) => {
    if (error.length > 0) {
      return (
        <>
          <DialogTitle id='title' onClose={onCancel}>
            <Typography component='div' variant='h4'>Änderung fehlgeschlagen</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs>
              <Grid container item>
                <Grid container item direction='column' justify='flex-start' wrap='nowrap'>
                  <Typography variant='subtitle2' className={classes.error}>{error}</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>Die Änderung der E-Mail-Adresse war nicht erfolgreich.</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>Für weitere Informationen wenden Sie sich bitte an den init-Support.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )
    } else if (success) {
      return (
        <>
          <DialogTitle id='title' onClose={onCancel}>
            <Typography component='div' variant='h4'>Neue E-Mail-Adresse hinterlegt</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs>
              <Grid container item>
                <Grid container item direction='column' justify='flex-start' wrap='nowrap'>
                  <Typography variant='subtitle2' color='textSecondary'>Die Änderung der E-Mail-Adresse war erfolgreich. </Typography>
                  <Typography variant='subtitle2' color='textSecondary'>Es wurde eine Verifikationsemail mit einem Code an {value} versendet. </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )
    } else {
      return (
        <div className={classes.centerSpinner}>
          <CircularProgress color='primary' />
        </div>
      )
    }
  }, [error, success, onCancel, classes.error, classes.centerSpinner])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='title'
      maxWidth='sm'
      fullWidth
    >
      <Formik
        initialValues={{
          EmailAddress: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setDialogState('popUp')
          onSubmit(values)
        }}
      >
        {({
          errors,
          handleChange,
          touched,
          values,
        }) => {
          if (dialogState === 'form') {
            return (
              <Form>
                <DialogTitle>
                  <Typography component='div' variant='h4'>
                    E-Mail-Adresse ändern
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Durch das Ändern der E-Mail-Adresse muss der Nutzer diese in der App erneut verifizieren.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    error={touched.EmailAddress && Boolean(errors.EmailAddress)}
                    fullWidth
                    helperText={(touched.EmailAddress ? errors.EmailAddress : '')}
                    id='EmailAddress'
                    label='E-Mail-Adresse'
                    onChange={handleChange}
                    // XXX no type because that causes browser validation before formik/yup validation
                    // type='email'
                    value={values.EmailAddress}
                  />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    color='primary'
                    fullWidth
                    onClick={() => {
                      handleClose()
                    }}
                    variant='outlined'
                    xs={6}
                  >
                    Abbrechen
                  </Button>
                  <Button
                    color='primary'
                    fullWidth
                    type='submit'
                    variant='contained'
                    xs={6}
                  >
                    Ändern
                  </Button>
                </DialogActions>
              </Form>)
          } else {
            return (
              <Grid className={classes.m2}>
                {renderContent(values.EmailAddress)}
                <Divider variant='middle' className={classes.mb1} />
                <DialogActions>
                  <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
                    <Typography variant='button' className={classes.button}>Schließen</Typography>
                  </Button>
                </DialogActions>
              </Grid>
            )
          }
        }}
      </Formik>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  centerSpinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  m2: {
    margin: theme.spacing(2),
  },
  button: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  error: {
    color: 'rgba(244, 67, 54)',
    marginBottom: theme.spacing(2),
  },
}))

UpdateEmailDialog.propTypes = {
  /** callback when dialog is cancelled */
  onCancel: PropTypes.func,
  /** callback wenn form in dialog is submitted, receives form data as argument */
  onSubmit: PropTypes.func,
  /** controls whether the dialog is shown */
  open: PropTypes.bool,
  /** string that contains possible error message when submit fails */
  error: PropTypes.string,
  /** bool that indicates that submit was successful */
  success: PropTypes.bool,
  /** sets the error state */
  setError: PropTypes.func,
}
export default UpdateEmailDialog
