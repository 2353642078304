/**
 *  wraps logging to handle conditional logging etc
 *  TODO replace with a proper logging library
 */
export default {
  error: (...args) => {
    if (_shouldLog()) {
      // eslint-disable-next-line no-console
      console.error(...args)
    }
  },
  warn: (...args) => {
    if (_shouldLog()) {
      // eslint-disable-next-line no-console
      console.warn(...args)
    }
  },
}

export const _shouldLog = () => {
  return process.env.NODE_ENV !== 'production'
}
