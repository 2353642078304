import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FirstPage from '@material-ui/icons/FirstPage'
import FilterList from '@material-ui/icons/FilterList'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import useInitClient from 'hooks/useInitClient'
import { useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import logger from 'util/logger'
import { OidcSecure } from '@axa-fr/react-oidc-context'

const SearchRow = (props) => {
  const { columns } = props

  const tds = columns?.map(td => {
    return (
      <td style={{ padding: '16px' }} key={td.field}>
        <TextField
          onChange={typeof td.updateFunc === 'function' ? (e) => {
            td.updateFunc(e.currentTarget.value)
          } : null}
          id={td.field}
          value={td.value}
          disabled={!td.isSearchable}
          label={td.filterPlaceholder}
          fullWidth
          InputProps={{
            endAdornment:
              (typeof td.updateFunc === 'function' && td.value !== '')
                ? (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={(e) => {
                        td.updateFunc(e.currentTarget.value)
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
                : null,
          }}
        />
      </td>
    )
  })

  return (tds ? <tr key='searchrow'>{tds}</tr> : null)
}

SearchRow.propTypes = {
  columns: PropTypes.array,
}

const UserTable = ({
  muiTableKey,
}) => {
  // local state for tmp saving search input
  const [cellPhoneNumber, updateCellPhoneNumber] = useState('')
  const [firstName, updateFirstName] = useState('')
  const [lastName, updateLastName] = useState('')
  const [email, updateEmail] = useState('')

  const firstRender = useRef(true)
  const history = useHistory()

  const tableRef = useRef()

  const initClient = useInitClient()

  // TODO Seeking better solution for focus handling on the searchInputs
  useEffect(() => {
    if (!firstRender.current && tableRef.current) {
      document.getElementById('CellPhoneNumber').focus()
      // triggers material-table search
      tableRef.current.onQueryChange()
    }
  }, [cellPhoneNumber])

  useEffect(() => {
    if (!firstRender.current && tableRef.current) {
      document.getElementById('FirstName').focus()
      // triggers material-table search
      tableRef.current.onQueryChange()
    }
  }, [firstName])

  useEffect(() => {
    if (!firstRender.current && tableRef.current) {
      document.getElementById('LastName').focus()
      // triggers material-table search
      tableRef.current.onQueryChange()
    }
  }, [lastName])

  useEffect(() => {
    if (!firstRender.current && tableRef.current) {
      document.getElementById('Email').focus()
      // triggers material-table search
      tableRef.current.onQueryChange()
    }
  }, [email])

  // hmm I like https://stackoverflow.com/a/53254028
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    }
  }, [])

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  return (
    <OidcSecure>
      <MaterialTable
        key={muiTableKey}
        tableRef={tableRef}
        icons={tableIcons}
        onRowClick={
          (event, rowData) => { history.push(generatePath('/userdetails/:id/', { id: rowData?.CustomerAccountId })) }
        }
        options={{
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100, 250],
          search: false,
          sorting: false,
          showEmptyDataSourceMessage: true,
          showTitle: false,
          toolbar: false,
          hideFilterIcons: true,
          headerStyle: {
            display: 'none',
          },
        }}
        columns={[
          { field: 'FirstName', filterPlaceholder: 'Vorname', width: 170, isSearchable: true, updateFunc: (val) => updateFirstName(val), value: firstName },
          { field: 'LastName', filterPlaceholder: 'Name', width: 170, isSearchable: true, updateFunc: (val) => updateLastName(val), value: lastName },
          { field: 'CellPhoneNumber', filterPlaceholder: 'Telefon', width: 170, isSearchable: true, updateFunc: (val) => updateCellPhoneNumber(val), value: cellPhoneNumber },
          { field: 'Email', filterPlaceholder: 'Email', width: 300, isSearchable: true, updateFunc: (val) => updateEmail(val), value: email },
          { field: 'Address.City', filterPlaceholder: 'Wohnort', width: 170, isSearchable: false },
          { field: 'CustomerAccountId', filterPlaceholder: 'User ID', width: 120, isSearchable: false },
        ]}
        components={{
          FilterRow: props => SearchRow(props),
        }}
        data={query => {
          return new Promise((resolve, reject) => {
            return _debouncedSearchFunction(
              initClient, firstName, lastName, cellPhoneNumber, email, query, resolve)
          })
        }}
        title=''
      />
    </OidcSecure>
  )
}

UserTable.propTypes = {
  muiTableKey: PropTypes.number,
}

// Defined outside of component, so we can debounce it
const _searchFunction = (
  initClient,
  firstName,
  lastName,
  cellPhoneNumber,
  email,
  query,
  resolve) => {
  return initClient.customerAccounts.listCustomerAccounts({
    FirstNamePattern: firstName !== '' ? firstName : null,
    LastNamePattern: lastName !== '' ? lastName : null,
    PhoneNumberPattern: cellPhoneNumber !== '' ? cellPhoneNumber : null,
    EmailPattern: email !== '' ? email : null,
    skip: query.page * query.pageSize,
    take: query.pageSize,
  })
    .then(response => {
      // This resolve with given structure is needed by material-table
      resolve({
        data: response.obj.Result || [],
        page: query.page,
        totalCount: response.obj.TotalCount,
      })
      return response
    },
    error => {
      logger.error('Error while fetching data', error)
    })
}

const _debouncedSearchFunction = AwesomeDebouncePromise(_searchFunction, 500)

export default UserTable
