import React from 'react'

import {
  createTheme,
} from 'util/theme'

import {
  ThemeProvider,
} from '@material-ui/core/styles'

import SmuProvider from 'util/SmuProvider'
import { createInitClientProvider } from 'hooks/useInitClient'

import Layout from 'components/Layout'

function App () {
  const InitClientProvider = createInitClientProvider()

  return (
    <ThemeProvider theme={createTheme()}>
      <InitClientProvider>
        <SmuProvider>
          <Layout />
        </SmuProvider>
      </InitClientProvider>
    </ThemeProvider>
  )
}

export default App
