import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import {
  LOGPAY_STATE_ACTIVE,
  MOBILITY_PROVIDER_KVV,
  MOBILITY_PROVIDER_STADTMOBIL,
  MOBILITY_PROVIDER_STATE_ACTIVE,
} from 'util/constants'

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  ListItemIcon,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons'

import SupportContactDialog from 'components/Dialog/SupportContactDialog'
import AccountRelationInfoDialog from 'components/Dialog/AccountRelationInfoDialog'

const UserAccountRelation = ({
  isActive,
  name,
}) => {
  const classes = useStyles()

  const accountRelationInfoDialogRef = useRef(null)

  const accountRelationInfo = useMemo(() => {
    if ((name === 'Stadtmobil' || name === 'Logpay') && isActive) {
      return (
        <Grid item xs className={classes.centerIcon} key={`${name}_${isActive}`}>
          <AccountRelationInfoDialog
            ref={accountRelationInfoDialogRef}
            name={name}
          />
          <ListItemIcon>
            <IconButton className='icon-information-circle' color='primary' size='small' onClick={() => accountRelationInfoDialogRef.current.show()} />
          </ListItemIcon>
        </Grid>
      )
    } else {
      return null
    }
  }, [accountRelationInfoDialogRef, name, isActive, classes.centerIcon])

  return (
    <ListItem>
      <Grid container spacing={2}>
        <Grid item>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: 'subtitle1' }}
          />
        </Grid>
        {accountRelationInfo}
      </Grid>
      <ListItemSecondaryAction className={classes.centerIcon}>
        <Box clone color={isActive ? 'success.main' : 'text.secondary'}>
          {isActive
            ? <CheckCircleOutlineIcon />
            : <HighlightOffIcon />}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

UserAccountRelation.propTypes = {
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
}

const UserAccountRelationsCard = props => {
  const classes = useStyles()
  const {
    isLoading,
    mobilityProviders,
    paymentAccountState,
  } = props

  const supportContactDialogRef = useRef(null)

  return (
    <Card square className='fullHeight'>
      <CardContent className='fullHeight'>
        {isLoading
          ? (
            <div className={`${classes.centerSpinner} fullHeight`}>
              <CircularProgress color='primary' />
            </div>
          )
          : (
            <List dense disablePadding>
              {mobilityProviders && mobilityProviders
                .sort((x, y) => {
                  const { MobilityProviderNumber: mobilityProviderNumberX } = x
                  const { MobilityProviderNumber: mobilityProviderNumberY } = y
                  return mobilityProviderNumberX < mobilityProviderNumberY
                    ? -1
                    : 1
                })
                .map(m => {
                  const {
                    HasASubscription,
                    MobilityProviderName,
                    State,
                    DriverLicense,
                  } = m
                  return (
                    <React.Fragment key={MobilityProviderName}>
                      <UserAccountRelation
                        isActive={MobilityProviderName === MOBILITY_PROVIDER_STADTMOBIL ? State === MOBILITY_PROVIDER_STATE_ACTIVE && DriverLicense?.IdentityNumber : State === MOBILITY_PROVIDER_STATE_ACTIVE}
                        name={MobilityProviderName}
                      />
                      {MobilityProviderName === MOBILITY_PROVIDER_KVV &&
                        (
                          <UserAccountRelation
                            isActive={HasASubscription}
                            name={`${MobilityProviderName} Abo`}
                          />
                        )}
                    </React.Fragment>
                  )
                })}
              {paymentAccountState && (
                <UserAccountRelation
                  isActive={paymentAccountState === LOGPAY_STATE_ACTIVE}
                  name='Logpay'
                />
              )}
            </List>
          )}
        <Button variant='outlined' color='primary' onClick={() => { supportContactDialogRef.current.show() }} fullWidth>
          Support Kontakte
        </Button>
        <SupportContactDialog ref={supportContactDialogRef} />
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  // centers single icon vertically in a Grid item
  centerIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  // centers spinner in CardContent
  centerSpinner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

UserAccountRelationsCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  mobilityProviders: PropTypes.array,
  paymentAccountState: PropTypes.string,
}

export default UserAccountRelationsCard
