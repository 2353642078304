
import React, { useState, createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import InitClientService from 'services/InitClientService'
import { useOidcAccessToken } from '@axa-fr/react-oidc-context'

// InitClient Context
const InitClientContext = createContext({})
InitClientContext.displayName = 'InitClientContext'

const createInitClientProvider = () => {
  const initClient = new InitClientService()

  const ContextProvider = ({ children }) => {
    const [api, setApi] = useState(null)
    const { accessToken } = useOidcAccessToken()
    initClient.ready().then(api => {
      setApi(api)
    })

    useEffect(() => {
      // XXX this works but there may be edge cases.
      // the regiomove app uses events handlers in the AuthenticationProvider
      // but different hierarchy of components does not allow that here
      if (accessToken) {
        initClient.setAccessToken(accessToken)
      } else {
        initClient.setAccessToken(null)
      }
    }, [accessToken])

    return api && (
      <InitClientContext.Provider value={api}>
        {children}
      </InitClientContext.Provider>
    )
  }

  ContextProvider.propTypes = {
    children: PropTypes.node,
  }

  return ContextProvider
}

const useInitClient = () => useContext(InitClientContext)

export { createInitClientProvider, useInitClient as default }
