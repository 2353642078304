import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext, useCallback } from 'react'
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Typography,
  Grid,
  Divider,
  makeStyles,
  withStyles,
  Link,
  ListItemText,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { SmuContext } from 'util/SmuProvider'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component='div' variant='h6'>{children}</Typography>
      {onClose &&
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(),
    padding: theme.spacing(),
  },
}))(MuiDialogActions)

/**
*  this dialog manages its internal "open" state but has an imperative handle to enable parent components to open it
*  see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
*/
const HomezoneInfoDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { smu } = useContext(SmuContext)
  const [stops, setStops] = useState([])

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true)
    },
  }))

  const handleClose = () => {
    setOpen(false)
  }

  const {
    radius,
    latitude,
    longitude,
  } = props

  const toDMS = (coordinate) => {
    const absolute = Math.abs(coordinate)
    const degrees = Math.trunc(absolute)
    const minutesNotTruncated = (absolute - degrees) * 60
    const minutes = Math.trunc(minutesNotTruncated)
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(3)

    return degrees + '° ' + minutes + "' " + seconds + '"'
  }

  const convertLatDMS = (coord) => {
    const latitude = toDMS(coord)
    const latitudeCardinal = coord >= 0 ? 'N' : 'S'
    return latitudeCardinal + ' ' + latitude
  }

  const convertLongDMS = (coord) => {
    const longitude = toDMS(coord)
    const longitudeCardinal = coord >= 0 ? 'E' : 'W'
    return longitudeCardinal + ' ' + longitude
  }

  const homeZoneURL = 'https://homezone.regiomove.de/?radius=' + (radius / 1000).toFixed(2) + '&lng=' + longitude + '&lat=' + latitude

  const useHomeZoneQueries = useCallback((lat, lng, radius) => {
    if (lat && lng && radius) {
      const getStops = () => {
        return (
          smu?.apis?.['home-zone-controller']?.getHomeZoneStops({
            lat,
            lng,
            // radius must not have decimal places
            radius: radius?.toFixed(),
          })
        )
          .then((response) => {
            const sortedList = response.body.sort((a, b) => a.properties.name.localeCompare(b.properties.name))
            setStops(sortedList)
          }
          )
      }
      getStops()
    }
  }, [smu])

  useEffect(() => {
    if (radius && longitude && latitude && open === true) {
      useHomeZoneQueries(latitude, longitude, radius)
    }
  }, [latitude, longitude, radius, open, useHomeZoneQueries])

  const renderMenuItems = stops.map((stop, index) => {
    return (
      <MenuItem key={index} value={stop.properties.name} style={{ pointerEvents: 'none' }}>
        <Icon className={['icon-publictransport', classes.margin]} />
        <ListItemText primary={stop.properties.name} />
      </MenuItem>
    )
  })

  return (
    <Dialog onClose={handleClose} aria-labelledby='title' open={open} fullWidth>
      <DialogTitle id='title' onClose={handleClose}>
        <Typography component='div' variant='h4' className={classes.mb2}>homezone Details</Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          Hier finden Sie alle Details, die zu dieser homezone vorliegen
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container item className={classes.mb2} spacing={4}>
          <Grid container item xs>
            <Grid item xs>
              <Typography className={classes.bold} color='textPrimary' variant='subtitle2'>Radius</Typography>
              <Grid container item className={classes.mb2}>
                <Grid item xs>
                  <Typography color='textSecondary'>
                    {(radius / 1000).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} km
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Typography className={classes.bold} color='textPrimary' variant='subtitle2'>Zentrum</Typography>
              <Grid container item>
                <Grid item xs>
                  <Typography color='textSecondary'>{convertLatDMS(latitude)}</Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs>
                  <Typography color='textSecondary'>{convertLongDMS(longitude)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Link color='inherit' underline='none' target='_blank' rel='noopener' href={homeZoneURL}>
              <Button variant='outlined' color='primary'>
                Ansicht im Webtool
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item className={classes.mb2}>
          <FormControl variant='standard' className={classes.select}>
            <InputLabel className={classes.bold}>Haltestellen</InputLabel>
            <Select
              label='stop'
              defaultValue=''
            >
              {renderMenuItems}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <Divider variant='middle' />
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const useStyles = makeStyles(theme => ({
  centerIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
  },
  text: {
    justifyContent: 'space-between',
    alignContent: 'space-evenly',
    wrap: 'nowrap',
  },
  link: {
    underline: 'none',
    color: 'primary',
  },
  mL2: {
    marginLeft: theme.spacing(2),
  },
  marginV2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  bold: {
    fontWeight: 600,
  },
  select: {
    minWidth: 400,
  },
}))
HomezoneInfoDialog.propTypes = {
  radius: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
}

export default HomezoneInfoDialog
