import {
  createMuiTheme,
} from '@material-ui/core/styles'

/**
 * Creates the theme for material ui
 */
export const createTheme = () => {
  return createMuiTheme({
    // overrides
    palette: {
      primary: {
        main: '#12297A', // regiomove blue
      },
    },
    mixins: {
      toolbar: {
        minHeight: 56,
      },
    },
    // Material UI component overrides
    // https://material-ui.com/customization/components/#global-theme-override
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 0,
        },
      },
      MuiInput: {
        underline: {
          '&$disabled': {
            '&:before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
            },
          },
        },
      },
      //     .MuiInput-underline.Mui-disabled:before {
      //   border-bottom-style: solid;
      //   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      // }
    },
    // additional theme variables
    raumo: {
      palette: {
        background: {
          main: '#EAEEFB',
        },
        typography: {
          textColorMediumEmphasisDark: 'rgba(0, 0, 0, 0.6)',
        },
      },
      layout: {
        loginCard: {
          maxWidth: 400,
        },
        sidebar: {
          width: 200,
        },
      },
    },
  })
}
