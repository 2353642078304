import React, { forwardRef, useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import clsx from 'clsx'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons'

import {
  Avatar,
  Button,
  IconButton,
  Link,
  Grid,
  Typography,
} from '@material-ui/core'

import { ReactComponent as Logo } from 'assets/homezone.svg'
import RefundDialog from 'components/Dialog/RefundDialog'
import RequestButton from '../RequestButton'
import HomezoneInfoDialog from 'components/Dialog/HomezoneInfoDialog'

/**
 * A component providing a (Material)table of Homezonebookings
 * @param homeZones
 * @param refundHomeZone
 * @returns {JSX.Element}
 * @constructor
 */

const formatTime = timeString => Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  day: '2-digit',
  month: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}).format(new Date(timeString))

const HomeZonesTable = ({
  homeZones,
  refundHomeZone,
}) => {
  const classes = useStyles()

  const homezoneInfoDialogRef = useRef(null)
  const [data, setData] = useState('')

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  const refundHomeZoneCallback = useCallback(
    (event, rowData) => {
      return refundHomeZone(rowData.HomeZone?.HomeZoneId, rowData.HomeZone.Price)
    },
    [refundHomeZone],
  )

  const renderFirstColumn = (rowData) => {
    const caption = 'homezone'
    const info = rowData.HomeZone?.NickName

    const handleOpen = () => {
      setData(rowData.HomeZone)
      homezoneInfoDialogRef.current.show()
    }
    return (
      <>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Avatar
              classes={{ root: classes.avatar }}
              style={{
                backgroundColor: '#1AC6AD',
              }}
            >
              <Logo />
            </Avatar>
          </Grid>
          <Grid item className={clsx(classes.pl05, classes.mr1)}>
            <Typography variant='body1' noWrap>{caption}</Typography>
            <Typography variant='body2' className={classes.caption} noWrap>{info}</Typography>
          </Grid>
          <Grid>
            <IconButton className='icon-information-circle' color='primary' size='small' onClick={handleOpen} />
            <HomezoneInfoDialog
              ref={homezoneInfoDialogRef}
              radius={data.Radius}
              longitude={data.GeoLocation?.Longitude}
              latitude={data.GeoLocation?.Latitude}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const renderColumn = (caption, info) => {
    return (
      <>
        <Typography variant='caption' noWrap className={classes.caption}>{caption}</Typography>
        <Typography variant='body2' noWrap>{info || 'nicht verfügbar'}</Typography>
      </>
    )
  }

  const renderColumnHomeZone = (rowData, column) => {
    const remainingSeconds = (new Date(rowData.TicketValidityEnd) - Date.now()) / 1000 / 60 / 60 / 24
    const kvvData = [
      { caption: 'ID', info: rowData.HomeZone?.HomeZoneId },
      { caption: 'Buchungsbeginn', info: formatTime(rowData.TicketValidityStart) },
      { caption: 'Buchungsende', info: formatTime(rowData.TicketValidityEnd) },
      { caption: 'Noch aktiv', info: rowData.isActive ? `${remainingSeconds.toFixed()} Tage` : '-' },
      { caption: 'Status', info: rowData.isActive ? 'aktiv' : 'inaktiv' },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.HomeZone?.Price / 100) },
    ]
    return renderColumn(kvvData[column].caption, kvvData[column].info)
  }

  const renderTableColumn = (rowData, column) => {
    return renderColumnHomeZone(rowData, column)
  }

  const homeZoneURL = rowData => {
    return `https://homezone.regiomove.de/?radius=${(rowData.HomeZone?.Radius / 1000).toFixed(2)}&lng=${rowData.HomeZone?.GeoLocation?.Longitude}&lat=${rowData.HomeZone?.GeoLocation?.Latitude}`
  }

  const renderWebtoolLink = (rowData) => {
    return (
      <Link color='inherit' underline='none' target='_blank' rel='noopener' href={homeZoneURL(rowData)}>
        <Button
          color='primary'
          className={classes.mr1}
          variant='outlined'
        >
          Im Webtool öffnen
        </Button>
      </Link>
    )
  }

  return (
    <Grid
      container
      className={classes.grid}
    >
      <Grid
        item
        className={classes.table}
      >
        <MaterialTable
          icons={tableIcons}
          components={{
            Action: RefundAction,
          }}
          options={{
            actionsColumnIndex: -1,
            draggable: false,
            emptyRowsWhenPaging: false,
            header: false,
            pageSize: 10,
            pageSizeOptions: [],
            search: false,
            showTitle: false,
            showFirstLastPageButtons: false,
            sorting: false,
            toolbar: false,
            tableLayout: 'auto',
            headerStyle: {
              whiteSpace: 'nowrap',
            },
          }}
          columns={[
            {
              field: 'MobilityProviderNumber ',
              render: rowData => renderFirstColumn(rowData),
            },
            {
              field: 'HomeZoneId',
              render: rowData => renderTableColumn(rowData, 0),
            },
            {
              field: 'vehicleInformation', // ticket number, bike number or vehicle number
              render: rowData => renderTableColumn(rowData, 1),
            },
            {
              field: 'bookingStart', // reservation start (nextbike), booking start (kvv, stadtmobil)
              render: rowData => renderTableColumn(rowData, 2),
            },
            {
              field: 'column-2', // booking start (nextbike), booking end (kvv, stadtmobil)
              render: rowData => renderTableColumn(rowData, 3),
            },
            {
              field: 'column-3', // rental state (nextbike, stadtmobil), starting zone (kvv)
              render: rowData => renderTableColumn(rowData, 4),
            },
            {
              field: 'Amount',
              render: rowData => renderTableColumn(rowData, 5),
            },
            {
              field: 'webtool',
              render: rowData => renderWebtoolLink(rowData),
              width: '20%',
            },
          ]}
          data={homeZones}
          actions={[
            {
              icon: 'refundButton',
              tooltip: 'Erstatten',
              onClick: refundHomeZoneCallback,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

const RefundAction = props => {
  const classes = useStyles()
  const [open, setRefundDialogOpen] = useState(false)
  const startDays = (new Date(props.data.TicketValidityStart) - Date.now()) / 1000 / 60 / 60 / 24
  const endDays = (new Date(props.data.TicketValidityEnd) - Date.now()) / 1000 / 60 / 60 / 24
  const isRefunded = Math.round(endDays - startDays) < 28
  // does not work
  // onst isRefunded = (new Date(props.data.TicketValidityEnd) < Date.now())

  if (props.data.isActive === true) {
    return (
      <>
        <RequestButton
          color='primary'
          className={classes.mr1}
          fullWidth
          disabled={!props.data.isActive}
          onClick={() => { setRefundDialogOpen(true) }}
          variant='outlined'
        >
          Erstatten
        </RequestButton>
        <RefundDialog
          open={open}
          onClose={() => { setRefundDialogOpen(false) }}
          refundingTicket={(event, refundComment) => props.action.onClick(event, props.data, refundComment)}
          name={props.data.HomeZone.NickName}
        />
      </>
    )
  } else {
    if (isRefunded) {
      return (
        <Grid>
          <Typography variant='caption' style={{ color: '#00000099' }}>
            Erstattet am
          </Typography>
          <Typography variant='body2'>
            {formatTime(props.data.TicketValidityEnd)}
          </Typography>
        </Grid>
      )
    } else {
      return (
        <Typography variant='caption'>Ausgelaufene homezone nicht erstattbar</Typography>
      )
    }
  }
}

HomeZonesTable.propTypes = {
  homeZones: PropTypes.array,
  refundHomeZone: PropTypes.func,
}

RefundAction.propTypes = {
  data: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 50,
    height: 50,
    color: 'white',
  },
  caption: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
    ...theme.typography.caption,
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  pl05: {
    paddingLeft: theme.spacing(0.5),
  },
  tabs: {
    margin: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  table: {
    width: '100%',
  },
}))

export default HomeZonesTable
