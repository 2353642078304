import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Divider,
  Typography,
  DialogContentText,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import useInitClient from 'hooks/useInitClient'

const PasswordChangeDialog = ({
  onClose,
  open,
  userId,
}) => {
  const initClient = useInitClient()
  const [dialogState, setDialogState] = useState('form')
  const validationSchema = Yup.object({
    OldPassword: Yup.string().required('Aktuelles Passwort erforderlich'),
    NewPassword: Yup.string().required('Ein neues Passwort ist erforderlich'),
    RepeatPassword: Yup.string().equals([Yup.ref('NewPassword')], 'Passwörter nicht identisch'),
  })

  const changePassword = (data, setErrors) => {
    initClient.users.passwordChange({ userId: userId }, {
      requestBody: {
        OldPassword: data.OldPassword,
        NewPassword: data.NewPassword,
      },
    },
    ).then((response) => {
      setDialogState('success')
    },
    ).catch((response) => {
      const errorMessage = response.response.body.Errors[0].Message
      if (errorMessage === 'Failed to update user password.') {
        setErrors({ OldPassword: 'Passwort nicht korrekt' })
      } else if (errorMessage === 'NewPassword value exceeds maximum field length.') {
        setErrors({ NewPassword: 'Das Passwort darf nicht länger als 51 Zeichen sein' })
      } else {
        setErrors({ OldPassword: errorMessage })
      }
    })
  }

  return (
    <>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={onClose}
      >
        <Formik
          initialValues={{
            OldPassword: '',
            NewPassword: '',
            RepeatPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            changePassword(values, setErrors)
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            values,
          }) => {
            if (dialogState === 'success') {
              return (
                <>
                  <DialogTitle>
                    <Typography component='div' variant='h4'>
                      Passwort geändert
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Ihr Passwort wurde erfolgreich geändert. Bitte melden Sie sich ab sofort mit Ihrem neuen Passwort an.
                    </DialogContentText>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        setDialogState('form')
                        onClose()
                      }}
                      xs={6}
                    >
                      Schließen
                    </Button>
                  </DialogActions>
                </>
              )
            } else {
              return (
                <Form>
                  <DialogTitle>
                    <Typography component='div' variant='h4'>
                      Passwort ändern
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      error={touched.OldPassword && Boolean(errors.OldPassword)}
                      fullWidth
                      helperText={(touched.OldPassword ? errors.OldPassword : '')}
                      type='password'
                      id='OldPassword'
                      label='Aktuelles Passwort'
                      onChange={handleChange}
                      value={values.EmailAddress}
                    />
                    <TextField
                      error={touched.NewPassword && Boolean(errors.NewPassword)}
                      fullWidth
                      helperText={(touched.NewPassword ? errors.NewPassword : '')}
                      type='password'
                      id='NewPassword'
                      label='Neues Passwort'
                      onChange={handleChange}
                      value={values.EmailAddress}
                    />
                    <TextField
                      error={touched.RepeatPassword && Boolean(errors.RepeatPassword)}
                      fullWidth
                      helperText={(touched.RepeatPassword ? errors.RepeatPassword : '')}
                      type='password'
                      id='RepeatPassword'
                      label='Passwort bestätigen'
                      onChange={handleChange}
                      value={values.RepeatPassword}
                    />
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Button
                      color='primary'
                      fullWidth
                      onClick={() => {
                        onClose()
                      }}
                      variant='outlined'
                      xs={6}
                    >
                      Abbrechen
                    </Button>
                    <Button
                      color='primary'
                      fullWidth
                      type='submit'
                      variant='contained'
                      xs={6}
                    >
                      Passwort ändern
                    </Button>
                  </DialogActions>
                </Form>
              )
            }
          }}
        </Formik>
      </Dialog>
    </>
  )
}

PasswordChangeDialog.propTypes = {
  /** Function to be thrown on closing the dialog **/
  onClose: PropTypes.func.isRequired,
  /** Boolean to determine if Dialog is Open or not **/
  open: PropTypes.bool.isRequired,
  /** Subject identifier of the init User **/
  userId: PropTypes.string,
}

export default PasswordChangeDialog
