import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Swagger from 'swagger-client'

export const SmuContext = createContext({})
SmuContext.displayName = 'SmuContext'

/**
 * initializes smu client, keeps global smu state, provides context for smu hooks
 *
 * @component
 */
const SmuProvider = ({
  children,
}) => {
  const [smu, setSmu] = useState(null)

  // Init SMU
  useEffect(() => {
    const basePath = '/proxy/smu/'
    const url = basePath + 'v3/api-docs'
    Swagger({
      url,
      requestInterceptor: req => {
        // XXX need to add basePath because swagger doesn't apply it automatically for some reason
        if (!req.url.startsWith(basePath)) {
          req.url = basePath.slice(0, -1) + req.url
        }
      },
    })
      .then(client => {
        // overwrite spec basePath with proxy path
        client.spec.basePath = basePath
        setSmu(client)
        return client
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error while creating SmuClient based on swaggerfile', err)
      })
  }, [])

  return (
    <SmuContext.Provider
      value={{
        smu: smu,
      }}
    >
      {children}
    </SmuContext.Provider>
  )
}

SmuProvider.propTypes = {
  children: PropTypes.node,
}

export default SmuProvider
