import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Create as CreateIcon,
} from '@material-ui/icons'

import useInitClient from 'hooks/useInitClient'
import logger from 'util/logger'
import UpdateEmailDialog from 'components/Dialog/UpdateEmailDialog'
import UpdateCellPhoneNumberDialog from 'components/Dialog/UpdateCellPhoneNumberDialog'
import VerificationSentDialog from 'components/Dialog/VerificationSentDialog'

const VerificationStatusCard = props => {
  const classes = useStyles()
  const initClient = useInitClient()

  const {
    cellPhoneNumber,
    email,
    isCellPhoneNumberConfirmed,
    isEmailConfirmed,
    state,
    userId,
    validFrom,
    onReload,
  } = props

  const [isUpdateEmailDialogOpen, setUpdateEmailDialogOpen] = useState(false)
  const [isUpdateCellPhoneNumberDialogOpen, setUpdateCellPhoneNumberDialogOpen] = useState(false)
  const [isVerificationDialogOpen, setVerificationDialogOpen] = useState(false)
  const [updateError, setUpdateError] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [verifcationError, setVerificationError] = useState('')
  const [verificationSuccess, setVerificationSuccess] = useState(false)

  const updateEmail = useCallback((data) => {
    initClient.customerAccounts.updateEmail({
      customerAccountId: userId,
    }, {
      requestBody: {
        ...data,
      },
    })
      .then(() => {
        onReload && onReload()
        setUpdateSuccess(true)
      })
      .catch((error) => {
        logger.error('Error while sending request', error)
        const errorMessage = error.response.body.Errors[0].Message
        setUpdateError(errorMessage)
      })
  }, [onReload, initClient.customerAccounts, userId])

  const sendVerificationEmail = useCallback(
    () => {
      initClient.customerAccounts.sendEmail({ customerAccountId: userId })
        .then(() => {
          initClient.customerAccounts.getCustomerDetails({ customerAccountId: userId })
        })
        .then(() => {
          setVerificationSuccess(true)
          setVerificationDialogOpen(true)
        })
        .catch((error) => {
          logger.error('Error while sending request', error)
          const errorMessage = error.response.body.Errors[0].Message
          setVerificationError(errorMessage)
          setVerificationDialogOpen(true)
        })
    },
    [initClient, userId],
  )

  const updateCellPhoneNumber = useCallback((data) => {
    initClient.customerAccounts.updateCellPhoneNumber({
      customerAccountId: userId,
    }, {
      requestBody: {
        ...data,
      },
    })
      .then(() => {
        onReload && onReload()
        setUpdateSuccess(true)
      })
      .catch((error) => {
        logger.error('Error while sending request', error)
        const errorMessage = error.response.body.Errors[0].Message
        setUpdateError(errorMessage)
      })
  }, [initClient.customerAccounts, onReload, userId])

  return (
    <Card square className='fullHeight'>
      <CardContent>
        <Grid container spacing={1} justify='space-between'>
          <Grid container item spacing={1} xs={6} direction='column'>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                {email}
              </Typography>
              <Box clone color={isEmailConfirmed ? 'success.main' : 'error.main'}>
                <Typography variant='caption'>
                  {isEmailConfirmed ? 'verifiziert' : 'nicht verifiziert'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item justify='space-between' xs={6} wrap='nowrap' spacing={1}>
            <Grid item>
              <Button
                className={classes.iconButton}
                color='primary'
                onClick={() => { setUpdateEmailDialogOpen(true) }}
                variant='outlined'
              >
                <CreateIcon />
              </Button>
              <UpdateEmailDialog
                onCancel={() => { setUpdateEmailDialogOpen(false) }}
                onSubmit={updateEmail}
                open={isUpdateEmailDialogOpen}
                error={updateError}
                setError={setUpdateError}
                success={updateSuccess}
              />
            </Grid>
            <Grid item className={clsx(classes.f11a)}>
              <Button
                color='primary'
                fullWidth
                onClick={sendVerificationEmail}
                variant='outlined'
              >
                Verifikation senden
              </Button>
              <VerificationSentDialog
                onCancel={() => { setVerificationDialogOpen(false) }}
                open={isVerificationDialogOpen}
                error={verifcationError}
                setError={setVerificationError}
                success={verificationSuccess}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={6} direction='column'>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                {cellPhoneNumber}
              </Typography>
              <Box clone color={isCellPhoneNumberConfirmed ? 'success.main' : 'error.main'}>
                <Typography variant='caption'>
                  {isCellPhoneNumberConfirmed ? 'verifiziert' : 'nicht verifiziert'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item justify='space-between' xs={6} wrap='nowrap' spacing={1}>
            <Grid item>
              <Button
                className={classes.iconButton}
                color='primary'
                onClick={() => { setUpdateCellPhoneNumberDialogOpen(true) }}
                variant='outlined'
              >
                <CreateIcon />
              </Button>
              <UpdateCellPhoneNumberDialog
                onCancel={() => { setUpdateCellPhoneNumberDialogOpen(false) }}
                onSubmit={updateCellPhoneNumber}
                open={isUpdateCellPhoneNumberDialogOpen}
                error={updateError}
                setError={setUpdateError}
                success={updateSuccess}
              />
            </Grid>
          </Grid>
          <Grid container item justify='space-between' xs={6} spacing={1}>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                Registriert seit:
              </Typography>
            </Grid>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                {Intl.DateTimeFormat('de-DE', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(new Date(validFrom))}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justify='space-between' xs={6} spacing={1}>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                Status:
              </Typography>
            </Grid>
            <Grid item>
              <Typography component='span' display='block' variant='subtitle1'>
                {state}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

VerificationStatusCard.propTypes = {
  /** Cell phone number */
  cellPhoneNumber: PropTypes.string,
  /** Email adress */
  email: PropTypes.string,
  /** Is cell phone number confirmed */
  isCellPhoneNumberConfirmed: PropTypes.bool,
  /** Is email adress confirmed */
  isEmailConfirmed: PropTypes.bool,
  /** Account state (Email and cell phone number must be verified/confirmed for the account to be activated) */
  state: PropTypes.string,
  /** ID of the customer account which is displayed */
  userId: PropTypes.string.isRequired,
  /** Contract is valid since that date */
  validFrom: PropTypes.string,
  /** Function for reloading user details */
  onReload: PropTypes.func,
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: '5px',
    minWidth: 0,
  },
  f11a: {
    flex: '1 1 auto',
  },
}))

export default VerificationStatusCard
