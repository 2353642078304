import React, { useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import {
  Drawer,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core'

import {
  PermIdentity as PermIdendityIcon,
  ContactSupport as ContactSupportIcon,
  ContactPhone as ContactPhoneIcon,
} from '@material-ui/icons'

import SupportContactDialog from 'components/Dialog/SupportContactDialog'
import SecondLevelContactDialog from 'components/Dialog/SecondLevelContactDialog'
import { useOidc } from '@axa-fr/react-oidc-context'

/**
 * Menu component
 */
const Menu = () => {
  const classes = useStyles()
  const { isAuthenticated } = useOidc()
  const supportContactDialogRef = useRef(null)
  const secondLevelContactDialogRef = useRef(null)

  return isAuthenticated && (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant='permanent'
      anchor='left'
    >
      <div className={classes.drawerContainer}>
        <List dense>
          <ListSubheader>Nutzer</ListSubheader>
          <Link component={RouterLink} color='inherit' underline='none' to='/usersearch'>
            <ListItem button>
              <ListItemIcon><PermIdendityIcon /></ListItemIcon>
              <ListItemText primary='Nutzersuche' />
            </ListItem>
          </Link>
          <ListSubheader>Nutzer-Hilfen</ListSubheader>
          <SupportContactDialog ref={supportContactDialogRef} />
          <ListItem button onClick={() => { supportContactDialogRef.current.show() }}>
            <ListItemIcon><ContactPhoneIcon /></ListItemIcon>
            <ListItemText primary='Support Kontakte' />
          </ListItem>
          {process.env.REACT_APP_CLIENT_ID === 'regiomove' &&
            <Link color='inherit' underline='none' target='_blank' href='https://kvv.de/regiomove/faq' rel='noopener'>
              <ListItem button>
                <ListItemIcon><ContactSupportIcon /></ListItemIcon>
                <ListItemText primary='regiomove FAQ' />
              </ListItem>
            </Link>}

          <ListSubheader>Mitarbeiter-Hilfen</ListSubheader>
          <SecondLevelContactDialog ref={secondLevelContactDialogRef} />
          <ListItem button onClick={() => { secondLevelContactDialogRef.current.show() }}>
            <ListItemIcon><ContactPhoneIcon /></ListItemIcon>
            <ListItemText primary='2nd-Level Support' />
          </ListItem>
          <Link color='inherit' underline='none' target='_blank' rel='noopener' href='https://raumobil.atlassian.net/servicedesk/customer/portal/3'>
            <ListItem button>
              <ListItemIcon><ContactSupportIcon /></ListItemIcon>
              <ListItemText primary='Wissensdatenbank' />
            </ListItem>
          </Link>
        </List>
      </div>
    </Drawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.raumo.layout.sidebar.width,
  },
  drawerPaper: {
    width: theme.raumo.layout.sidebar.width,
  },
  drawerContainer: {
    paddingTop: theme.mixins.toolbar.minHeight,
    overflow: 'auto',
  },
}))

export default Menu
