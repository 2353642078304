import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core'

import { Formik, Form } from 'formik'
import RequestButton from 'components/RequestButton'
import { BOOKING_PROVIDER_KVV, BOOKING_PROVIDER_TGO, BOOKING_PROVIDER_VOI, CLIENT_ID } from 'util/constants'
import { getBookingProvider } from 'util/booking'

const RefundDialog = ({ onClose, open, bookingID, ticketID, refundingTicket, mobilityProvider, name, comment, isCommentAllowed }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [refundComment, setRefundComment] = useState('')

  const renderHeader = useCallback(() => {
    if (bookingID === undefined) {
      return (
        <Typography variant='h4'>Homezone erstatten?</Typography>
      )
    } else if (mobilityProvider === getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID) || mobilityProvider === getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)) {
      return (
        <Typography variant='h4'>Ticket erstatten?</Typography>
      )
    } else if (mobilityProvider === getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID)) {
      return (
        <Typography variant='h4'>Voi erstatten?</Typography>
      )
    } else {
      return null
    }
  }, [bookingID, mobilityProvider])

  const renderContent = useCallback(() => {
    if (bookingID === undefined) {
      return (
        <Typography className={classes.mb2}>Sind Sie sicher, dass Sie die homezone mit Namen {name} erstatten wollen?</Typography>
      )
    } else if (mobilityProvider === getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID) || mobilityProvider === getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)) {
      return (
        <>
          {error &&
            <Box className={classes.error}>
              <Typography className={classes.mb2}>
                Die Buchung konnte nicht erstattet werden:
              </Typography>
              <Typography variant='caption'>
                {error}
              </Typography>
            </Box>}
          <Typography className={classes.mb2}>Sind Sie sicher, dass Sie das Ticket
            <Typography className={clsx(classes.bd, classes.inline)}> (ID {ticketID})
            </Typography> mit Buchungsnummer <Typography className={clsx(classes.bd, classes.inline)}>{bookingID} </Typography>
            erstatten wollen?
          </Typography>
          <Typography className={classes.mb2}>LogPay fasst Buchungen mehrerer Tage zusammen. So wird der Erstattungsbetrag gegebenenfalls mit anderen Buchungen verrechnet.</Typography>
        </>
      )
    } else if (mobilityProvider === getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID)) {
      return (
        <>
          {error &&
            <Box className={classes.error}>
              <Typography className={classes.mb2}>
                Die Buchung konnte nicht erstattet werden:
              </Typography>
              <Typography variant='caption'>
                {error}
              </Typography>
            </Box>}
          <Typography className={classes.mb2}>Sind Sie sicher, dass Sie die Voi Buchung
            <Typography className={clsx(classes.bd, classes.inline)}> (ID {ticketID})
            </Typography> mit Buchungsnummer <Typography className={clsx(classes.bd, classes.inline)}>{bookingID} </Typography>
            erstatten wollen?
          </Typography>
          <Typography className={classes.mb2}>LogPay fasst Buchungen mehrerer Tage zusammen. So wird der Erstattungsbetrag gegebenenfalls mit anderen Buchungen verrechnet.</Typography>
        </>
      )
    } else {
      return null
    }
  }, [bookingID, mobilityProvider, error, name, ticketID, classes])

  const handleChange = (e) => {
    setRefundComment(e.target.value)
  }

  const handleClose = () => {
    setRefundComment('')
    onClose()
  }

  return (
    <>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={{
            Comment: comment,
          }}
        >
          {({
            values,
          }) => {
            return (
              <Form>
                <DialogTitle>
                  {renderHeader()}
                </DialogTitle>
                <DialogContent>
                  {renderContent()}
                  {isCommentAllowed === true
                    ? (
                      <TextField
                        fullWidth
                        multiline
                        id='Comment'
                        onChange={handleChange}
                        minRows={4}
                        variant='outlined'
                        value={values.Comment}
                        placeholder='Kommentar hinterlassen...'
                        inputProps={{ maxLength: 1000 }}
                      />
                    ) : (
                      null
                    )}
                </DialogContent>
                <Divider variant='middle' />
                <DialogActions>
                  {error
                    ? (
                      <Button onClick={handleClose} variant='contained' color='primary'>Ok</Button>
                    ) : (
                      <>
                        <Button onClick={handleClose} variant='contained'>
                          Abbrechen
                        </Button>
                        <RequestButton
                          autoFocus
                          isLoading={isLoading}
                          onClick={(event, rowData) => {
                            setIsLoading(true)
                            refundingTicket(event, refundComment, rowData)
                              .then(() => { setIsLoading(false) })
                              .catch((error) => {
                                const errorMessage = error.response.body.Errors[0].Message
                                return (
                                  setError(errorMessage),
                                  setIsLoading(false)
                                )
                              })
                          }}
                          variant='contained'
                          color='primary'
                          type='submit'
                        >
                          Bestätigen
                        </RequestButton>
                      </>
                    )}
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  bd: {
    fontWeight: 'bold',
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
  error: {
    color: 'rgba(244, 67, 54)',
    marginBottom: theme.spacing(2),
  },
}))

RefundDialog.propTypes = {
  /** Function to be thrown on closing the dialog **/
  onClose: PropTypes.func.isRequired,
  /** Function to refund ticket **/
  refundingTicket: PropTypes.func.isRequired,
  /** Function to be thrown on opening Dialog **/
  open: PropTypes.bool.isRequired,
  /** Booking ID **/
  bookingID: PropTypes.number,
  /** Ticket ID **/
  ticketID: PropTypes.number,
  /** Mobility Provider */
  mobilityProvider: PropTypes.string,
  /** Homezone Name **/
  name: PropTypes.string,
  /** Comment **/
  comment: PropTypes.string,
  /** Boolean to determine whether refund comment is possible or not */
  isCommentAllowed: PropTypes.bool,
}

export default RefundDialog
