import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import UserCommentDialog from 'components/Dialog/UserCommentDialog'
import useInitClient from 'hooks/useInitClient'
import logger from 'util/logger'

/**
 * A component providing a (Material)Card of comments
 * @param comment
 * @param onReload
 * @param userId
 * @returns {JSX.Element}
 * @constructor
 */

const CommentCard = (props) => {
  const {
    comment,
    onReload,
    userId,
  } = props

  const classes = useStyles()

  const initClient = useInitClient()

  const [isUserCommentDialogOpen, setUserCommentDialogOpen] = useState(false)

  const hasNoComment = useMemo(() => { return (comment?.length === 0 || comment === '-') }, [comment])

  const updateComment = useCallback((data) => {
    initClient.customerAccounts.commentCustomerAccount({
      customerAccountId: userId,
    }, {
      requestBody: {
        ...data,
      },
    })
      .then(() => {
        onReload && onReload()
      })
      .catch((error) => {
        logger.error('Error while sending request', error)
      })
  }, [initClient.customerAccounts, onReload, userId])

  return (
    <Card square className='fullHeight'>
      <CardContent>
        <Grid container>
          {hasNoComment ? (
            <Grid container item className={classes.noComment}>
              <Typography variant='subtitle1' color='textSecondary' style={{ whiteSpace: 'pre-line' }}>
                Noch kein Kommentar hinterlassen
              </Typography>
            </Grid>
          ) : (
            <Grid container item>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {comment}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions
        className={classes.cardActions}
      >
        <Button
          color='primary'
          className={classes.m1}
          variant='outlined'
          onClick={() => { setUserCommentDialogOpen(true) }}
        >
          {hasNoComment ? 'Kommentar schreiben' : 'Kommentar bearbeiten'}
        </Button>
        <UserCommentDialog
          comment={comment}
          onCancel={() => { setUserCommentDialogOpen(false) }}
          onSubmit={updateComment}
          open={isUserCommentDialogOpen}
          reload={onReload}
        />
      </CardActions>
    </Card>
  )
}

CommentCard.propTypes = {
  /** Comment value from user object which is displayed */
  comment: PropTypes.string,
  /** ID of the customer account which is displayed */
  userId: PropTypes.string.isRequired,
  /** Function for reloading user details */
  onReload: PropTypes.func,
}

const useStyles = makeStyles(theme => ({
  m1: {
    margin: theme.spacing(1),
  },
  noComment: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default CommentCard
