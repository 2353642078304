import React, { forwardRef, useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

import RequestButton from 'components/RequestButton'

import {
  BOOKING_PROVIDER_IOKI,
  BOOKING_PROVIDER_KVV,
  BOOKING_PROVIDER_NEXTBIKE,
  BOOKING_PROVIDER_STADTMOBIL,
  BOOKING_PROVIDER_TGO,
  BOOKING_PROVIDER_VOI,
  BOOKING_PROVIDER_ZEO,
  BOOKING_SALE_TYPE_REFUND,
  BOOKING_SALE_TYPE_SALE,
  BOOKING_SALE_TYPE_VOIDED,
  BOOKING_TYPE_ACTIVE,
  BOOKING_TYPE_CANCELED,
  BOOKING_TYPE_CLEARED,
  BOOKING_TYPE_FAILED,
  BOOKING_TYPE_RESERVED,
  CLIENT_ID,
  NEXTBIKE_BIKE_TYPES,
} from 'util/constants'

import {
  getBookingProviderColor,
  getBookingProviderIcon,
  formatBookingState,
  getActiveBookingState,
  groupPrice,
  getDisplayNameForMobilityProvider,
  getBookingProvider,
  getMobilityProviderNumberForBookingProvider,
} from 'util/booking'

import {
  AddBox,
  ArrowDownward,
  Chat,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import logger from 'util/logger'
import useInitClient from 'hooks/useInitClient'
import RefundDialog from 'components/Dialog/RefundDialog'
import RefundCommentDialog from 'components/Dialog/RefundCommentDialog'
import { useParams } from 'react-router-dom'

import {
  Avatar,
  Checkbox,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'

const formatTime = timeString => {
  if (!timeString) {
    return '-' // Default value for invalid or missing date
  }
  try {
    const date = new Date(timeString)
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date')
    }
    return Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }).format(date)
  } catch (error) {
    return '-' // Default value
  }
}

// http://85.115.11.167:8098/RegioMove/RegioMoveAPI/Help/ResourceModel?modelName=BookingItemPaymentType
const filterOptions = [
  {
    value: '4',
    label: 'Erstattet',
    parameter: 'BookingItemPaymentType',
  },
]

const BookingTable = (props) => {
  const {
    refundBookingItem,
  } = props

  const classes = useStyles()
  const initClient = useInitClient()
  const { userId } = useParams()

  const [tabFilter, setTabFilter] = useState(null)
  // filter needs to be an array to work with material ui select implementation
  const [filter, setFilter] = useState([])

  const [mobilityProviders, setMobilityProviders] = useState([])

  useEffect(() => {
    initClient.mobilityProviders.getAll().then(
      (result) => { setMobilityProviders(result.body.Result) }
    )
  }, [initClient.mobilityProviders])

  const refundBookingItemCallback = useCallback(
    (event, rowData, refundComment) => {
      const mobilityProviderNumber = getMobilityProviderNumberForBookingProvider(rowData.MobilityProviderNumber)
      if (rowData.MobilityProviderNumber === getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID)) {
        return refundBookingItem(mobilityProviderNumber, rowData.BookingItemID, refundComment)
          .then(() => {
            tableRef.current.onQueryChange()
          })
      } else if (rowData.MobilityProviderNumber === getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID)) {
        return refundBookingItem(mobilityProviderNumber, rowData.BookingItemID, refundComment)
          .then(() => {
            tableRef.current.onQueryChange()
          })
      } else if (rowData.MobilityProviderNumber === getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)) {
        return refundBookingItem(mobilityProviderNumber, rowData.BookingItemID, refundComment)
          .then(() => {
            tableRef.current.onQueryChange()
          })
      }
    },
    [refundBookingItem],
  )

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  const renderColumn = (caption, info) => {
    return (
      <>
        <Typography variant='caption' noWrap className={classes.caption}>{caption}</Typography>
        <Typography variant='body2' noWrap>{info || ''}</Typography>
      </>
    )
  }

  // add city if information is available
  const addCity = (station, city) => {
    if (city) {
      return station + ', ' + city
    }
    return station
  }

  const formatNextbikePrice = (amount, bookingState) => {
    const isReserved = bookingState === BOOKING_TYPE_RESERVED
    const isCanceled = bookingState === BOOKING_TYPE_CANCELED
    const isFailed = bookingState === BOOKING_TYPE_FAILED
    return isReserved || isCanceled || isFailed ? (
      '-'
    ) : (
      Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount / 100)
    )
  }
  const calculateDurationInMinutes = (start, end) => {
    const duration = Math.floor(((end.getTime() - start.getTime()) / 1000))
    return Math.ceil(duration / 60)
  }

  const formatVoiPrice = (amount, bookingDate, featureData, bookingState) => {
    const isReserved = bookingState === BOOKING_TYPE_RESERVED
    const isCanceled = bookingState === BOOKING_TYPE_CANCELED
    const isFailed = bookingState === BOOKING_TYPE_FAILED
    const isActive = bookingState === BOOKING_TYPE_ACTIVE
    const startTime = new Date(bookingDate)
    const currentTime = new Date(Date.now())

    if (isActive) {
      const featureDataObject = JSON.parse(featureData)
      const duration = calculateDurationInMinutes(startTime, currentTime)
      const basePrice = featureDataObject?.tariffs?.basePrice?.value
      const durationTariff = featureDataObject?.tariffs?.durationTariff?.price?.value

      const currentPrice = () => {
        const price = duration * durationTariff + basePrice
        return Number.isNaN(price) ? 0 : Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
      }
      const price = currentPrice()
      return price
    } else if (isReserved || isCanceled || isFailed) {
      return '-'
    } else {
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount / 100)
    }
  }

  const formatVoiDuration = (bookingDate, endDate, bookingState) => {
    const isReserved = bookingState === BOOKING_TYPE_RESERVED
    const isCanceled = bookingState === BOOKING_TYPE_CANCELED
    const isFailed = bookingState === BOOKING_TYPE_FAILED
    const isActive = bookingState === BOOKING_TYPE_ACTIVE
    const startTime = new Date(bookingDate)
    const endTime = new Date(endDate)
    const currentTime = new Date(Date.now())

    const durationInHoursAndMinutes = (totalMinutes) => {
      const minutes = totalMinutes % 60
      const hours = Math.floor(totalMinutes / 60)
      const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0')
      }
      return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
    }

    if (isCanceled || isReserved || isFailed) {
      return '-'
    } else {
      const durationInMinutes = calculateDurationInMinutes(startTime, isActive ? currentTime : endTime)
      return durationInMinutes < 60 ? `${durationInMinutes} min` : `${durationInHoursAndMinutes(durationInMinutes)} h`
    }
  }

  const formatVoiCode = (data) => {
    if (typeof data !== 'undefined') {
      if (data.includes('code":"')) {
        const splitted = data.split('code":"')
        return splitted[1].substring(0, 4).toUpperCase()
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }

  const formatStatus = (data, bookingState) => {
    const isActiveThroughValidity = new Date(data.EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isVoided = Boolean(data.Payments.find(p => p.PaymentState === BOOKING_SALE_TYPE_VOIDED))
    const isRefunded = Boolean(data.BookingType === BOOKING_TYPE_CLEARED && data.Amount === 0 && !isVoided)

    if (isRefunded) {
      return 'Erstattet'
    } else if (isVoided) {
      return 'Annulliert'
    } else if (isActiveThroughValidity) {
      const state = formatBookingState(BOOKING_TYPE_ACTIVE)
      return state
    } else {
      const state = formatBookingState(bookingState)
      return state
    }
  }

  const renderColumnKvvHead = (rowData, column) => {
    const kvvHead = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Ticket ID', info: 'ausklappen' },
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Starthaltestelle', info: addCity(rowData.BookingItems[0].FeatureName, rowData.BookingItems[0].FeatureData?.city) },
      { caption: 'Status', info: 'ausklappen' },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(groupPrice(rowData.BookingItems)) },
    ]

    return renderColumn(kvvHead[column].caption, kvvHead[column].info)
  }

  const renderColumnKvvChild = (rowData, bookingNumber, column) => {
    const bookingState = getActiveBookingState(rowData.RentalState, rowData.BookingType, rowData.EndTime)
    const isActiveThroughValidity = new Date(rowData.EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingType === BOOKING_TYPE_CLEARED && rowData.Amount === 0)

    const kvvChildData = [
      { caption: 'Buchungsnummer', info: bookingNumber },
      { caption: 'Ticket ID', info: rowData.BookingItemID },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.Amount / 100) },
    ]
    return renderColumn(kvvChildData[column].caption, kvvChildData[column].info)
  }

  const renderColumnKvvSingle = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType, rowData.BookingItems[0].EndTime)
    const isActiveThroughValidity = new Date(rowData.BookingItems[0].EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingItems[0].BookingType === BOOKING_TYPE_CLEARED && rowData.BookingItems[0].Amount === 0)
    const kvvData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Ticket ID', info: rowData.BookingItems[0].BookingItemID },
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Starthaltestelle', info: addCity(rowData.BookingItems[0].FeatureName, rowData.BookingItems[0].FeatureData?.city) },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.BookingItems[0].Amount / 100) },
    ]
    return renderColumn(kvvData[column].caption, kvvData[column].info)
  }
  const renderColumnTgoHead = (rowData, column) => {
    const head = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Ticket ID', info: 'ausklappen' },
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Start und Ziel', info: <>Start: {rowData.BookingItems[0].StartPlace} <br /> Ziel: {rowData.BookingItems[0].EndPlace}</> },
      { caption: 'Status', info: 'ausklappen' },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(groupPrice(rowData.BookingItems)) },
    ]

    return renderColumn(head[column].caption, head[column].info)
  }

  const renderColumnTgoChild = (rowData, bookingNumber, column) => {
    const bookingState = getActiveBookingState(rowData.RentalState, rowData.BookingType, rowData.EndTime)
    const isActiveThroughValidity = new Date(rowData.EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingType === BOOKING_TYPE_CLEARED && rowData.Amount === 0)

    const childData = [
      { caption: 'Buchungsnummer', info: bookingNumber },
      { caption: 'Ticket ID', info: rowData.BookingItemID },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.Amount / 100) },
    ]
    return renderColumn(childData[column].caption, childData[column].info)
  }

  const renderColumnTgoSingle = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType, rowData.BookingItems[0].EndTime)
    const isActiveThroughValidity = new Date(rowData.BookingItems[0].EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingItems[0].BookingType === BOOKING_TYPE_CLEARED && rowData.BookingItems[0].Amount === 0)
    const data = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Ticket ID', info: rowData.BookingItems[0].BookingItemID },
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Start und Ziel', info: <>Start: {rowData.BookingItems[0].StartPlace} <br /> Ziel: {rowData.BookingItems[0].EndPlace}</> },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.BookingItems[0].Amount / 100) },
    ]
    return renderColumn(data[column].caption, data[column].info)
  }

  const renderColumnNextbike = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType)
    const nextbikeData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Radnummer', info: rowData.BookingItems[0].VehicleReference },
      { caption: 'Reservierungsbeginn', info: formatTime(rowData.BookingDate) },
      { caption: 'Buchungsbeginn', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Buchungsende', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Status', info: formatBookingState(bookingState) },
      { caption: 'Preis', info: formatNextbikePrice(rowData.BookingItems[0].Amount, bookingState) },
    ]
    return renderColumn(nextbikeData[column].caption, nextbikeData[column].info)
  }

  const renderColumnStadtmobil = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType)
    const stadtmobilData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Fahrzeugnummer', info: rowData.BookingItems[0].VehicleReference },
      { caption: 'Buchungsbeginn', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Buchungsende', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Stationsnummer', info: rowData.BookingItems[0].StartPlace },
      { caption: 'Status', info: formatBookingState(bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.BookingItems[0].Amount / 100) },
    ]
    return renderColumn(stadtmobilData[column].caption, stadtmobilData[column].info)
  }

  const renderColumnZeo = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType)
    const stadtmobilData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Fahrzeugnummer', info: rowData.BookingItems[0].VehicleReference },
      { caption: 'Buchungsbeginn', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Buchungsende', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Stationsnummer', info: rowData.BookingItems[0].StartPlace },
      { caption: 'Status', info: formatBookingState(bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.BookingItems[0].Amount / 100) },
    ]
    return renderColumn(stadtmobilData[column].caption, stadtmobilData[column].info)
  }

  const renderColumnVoi = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType)
    const voiData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Fahrzeugcode', info: formatVoiCode(rowData.BookingItems[0].FeatureData) },
      { caption: 'Dauer', info: formatVoiDuration(rowData.BookingDate, rowData.BookingItems[0].EndTime, bookingState) },
      { caption: 'Buchungsbeginn', info: formatTime(rowData.BookingDate) },
      { caption: 'Buchungsende', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: 'Status', info: formatStatus(rowData.BookingItems[0], bookingState) },
      { caption: 'Preis', info: formatVoiPrice(rowData.BookingItems[0].Amount, rowData.BookingDate, rowData.BookingItems[0].FeatureData, bookingState) },
    ]
    return renderColumn(voiData[column].caption, voiData[column].info)
  }

  const renderColumnIokiHead = (rowData, column) => {
    const iokiHead = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      // { caption: 'Buchungsnummer', info: rowData },
      { caption: 'Ticket ID', info: 'ausklappen' },
      // BookingItems[0] - IOKI, BookingItems[1] - KVV. KVV BookingItems have a Start. Ioki does not
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[1].EndTime) },
      { caption: 'Starthaltestelle', info: addCity(rowData.BookingItems[1].FeatureName, rowData.BookingItems[1].FeatureData?.city) },
      { caption: 'Status', info: 'ausklappen' },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(groupPrice(rowData.BookingItems)) },
    ]
    return renderColumn(iokiHead[column].caption, iokiHead[column].info)
  }

  const renderColumnIokiChild = (rowData, bookingNumber, column) => {
    const bookingState = getActiveBookingState(rowData.RentalState, rowData.BookingType, rowData.EndTime)
    const isActiveThroughValidity = new Date(rowData.EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingType === BOOKING_TYPE_CLEARED && rowData.Amount === 0)

    const iokiChildData = [
      { caption: 'Buchungsnummer', info: bookingNumber },
      { caption: 'Ticket ID', info: rowData.BookingItemID },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: '', info: '' },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.Amount / 100) },
    ]
    return renderColumn(iokiChildData[column].caption, iokiChildData[column].info)
  }

  const renderColumnIokiSingle = (rowData, column) => {
    const bookingState = getActiveBookingState(rowData.BookingItems[0].RentalState, rowData.BookingItems[0].BookingType, rowData.BookingItems[0].EndTime)
    const isActiveThroughValidity = new Date(rowData.BookingItems[0].EndTime).valueOf() > Date.now() && bookingState === BOOKING_TYPE_CLEARED
    const isRefunded = Boolean(rowData.BookingItems[0].BookingType === BOOKING_TYPE_CLEARED && rowData.BookingItems[0].Amount === 0)
    const iokiData = [
      { caption: 'Buchungsnummer', info: rowData.BookingID },
      { caption: 'Ticket ID', info: rowData.BookingItems[0].BookingItemID },
      { caption: 'Gültig ab', info: formatTime(rowData.BookingItems[0].StartTime) },
      { caption: 'Gültig bis', info: formatTime(rowData.BookingItems[0].EndTime) },
      { caption: '', info: '' },
      { caption: 'Status', info: isRefunded ? 'Erstattet' : formatBookingState(isActiveThroughValidity ? BOOKING_TYPE_ACTIVE : bookingState) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rowData.BookingItems[0].Amount / 100) },
    ]
    return renderColumn(iokiData[column].caption, iokiData[column].info)
  }

  const renderTableColumn = (rowData, column) => {
    switch (rowData.BookingItems[0].MobilityProviderNumber) {
      case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
        if (rowData.BookingItems.length === 1) {
          return renderColumnTgoSingle(rowData, column)
        } else {
          return renderColumnTgoHead(rowData, column)
        }
      }
      case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID): {
        if (rowData.BookingItems.length === 1) {
          return renderColumnKvvSingle(rowData, column)
        } else {
          return renderColumnKvvHead(rowData, column)
        }
      }
      case getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID): {
        return renderColumnNextbike(rowData, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_STADTMOBIL, CLIENT_ID): {
        return renderColumnStadtmobil(rowData, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_ZEO, CLIENT_ID): {
        return renderColumnZeo(rowData, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID): {
        return renderColumnVoi(rowData, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
        if (rowData.BookingItems.length === 1) {
          return renderColumnIokiSingle(rowData, column)
        } else {
          return renderColumnIokiHead(rowData, column)
        }
      }
      default: {
        return null
      }
    }
  }

  const renderTableColumnChildren = (rowData, bookingNumber, column) => {
    switch (rowData.MobilityProviderNumber) {
      case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
        return renderColumnIokiChild(rowData, bookingNumber, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
        return renderColumnTgoChild(rowData, bookingNumber, column)
      }
      case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID):
      default: {
        return renderColumnKvvChild(rowData, bookingNumber, column)
      }
    }
  }

  const tableRef = useRef()
  const inputRef = useRef()

  return (
    <Grid
      container
      className={classes.grid}
    >
      <Grid
        item
        className={classes.table}
      >
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          components={{
            Action: RefundAction,
            Toolbar: props => (
              <Toolbar disableGutters>
                <MobilityProviderTabs
                  mobilityProviders={mobilityProviders}
                  onChange={(event, tabFilter) => {
                    setTabFilter(tabFilter)
                    // eslint-disable-next-line react/prop-types
                    props.onSearchChanged()
                  }}
                  value={tabFilter}
                />
                {/* https://v4.mui.com/components/selects/#multiple-select */}
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id='filter-label'
                    // XXX always shrink to match search field
                    shrink
                  >
                    Filter
                  </InputLabel>
                  <Select
                    input={<Input />}
                    labelId='filter-label'
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                    multiple
                    onChange={(e) => {
                      setFilter(e?.target?.value ?? null)
                      // eslint-disable-next-line react/prop-types
                      props.onSearchChanged()
                    }}
                    renderValue={(selected) => selected?.length ? selected.map(s => s.label).join(', ') : '-'}
                    // required to render something if nothing is selected
                    displayEmpty
                    value={filter}
                  >
                    {
                      filterOptions.map((fo) => (
                        <MenuItem
                          key={fo.parameter + fo.value}
                          value={fo}
                        >
                          <Checkbox checked={filter.indexOf(fo) > -1} />
                          <ListItemText primary={fo.label} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor='search'
                    // label animation is limited for number fields, so lock it to shrunk state
                    // https://v4.mui.com/components/text-fields/#shrink
                    shrink
                  >
                    Buchungsnummer
                  </InputLabel>
                  <Input
                    inputRef={inputRef}
                    startAdornment={(
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    )}
                    endAdornment={(
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            inputRef.current.value = ''
                            inputRef.current.focus()
                            // eslint-disable-next-line react/prop-types
                            props.onSearchChanged('')
                          }}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )}
                    onChange={() => {
                      if (inputRef.current.value.length >= 5 || inputRef.current.value === '') {
                        // eslint-disable-next-line react/prop-types
                        props.onSearchChanged(inputRef.current.value)
                      }
                    }}
                    id='search'
                    type='number'
                  />
                </FormControl>
              </Toolbar>
            ),
          }}
          detailPanel={[
            rowData => ({
              disabled: rowData.BookingItems.length === 1,
              icon: () => <ChevronRight className={rowData.BookingItems.length === 1 && classes.displayNone} />,
              render: (rowData) => {
                const bookingNumber = rowData.BookingID
                if (rowData.BookingItems.length > 1) {
                  return (
                    <Table>
                      <TableBody>
                        {
                          rowData.BookingItems.map((bookingItem, index) => {
                            return (
                              <TableRow key={index}>
                                {/* XXX an empty cell to adust for the detail panel button in the main row */}
                                <TableCell style={{ width: 55 }} />
                                {/* XXX enforce approximately same width as columns in main table */}
                                <TableCell style={{ width: 300 }}>
                                  <FirstColumnOneBookingItemCell bookingItem={bookingItem} />
                                </TableCell>
                                <TableCell style={{ width: 175 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '0')}
                                </TableCell>
                                <TableCell style={{ width: 135 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '1')}
                                </TableCell>
                                <TableCell style={{ width: 190 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '2')}
                                </TableCell>
                                <TableCell style={{ width: 190 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '3')}
                                </TableCell>
                                <TableCell style={{ width: 230 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '4')}
                                </TableCell>
                                <TableCell style={{ width: 110 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '5')}
                                </TableCell>
                                <TableCell style={{ width: 90 }}>
                                  {renderTableColumnChildren(bookingItem, bookingNumber, '6')}
                                </TableCell>
                                <TableCell style={{ width: 150 }}>
                                  <RefundAction
                                    action={{ onClick: refundBookingItemCallback }}
                                    data={rowData}
                                    index={index}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  )
                } else { return false }
              },
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            draggable: false,
            emptyRowsWhenPaging: false,
            header: false,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100, 250],
            search: false,
            showEmptyDataSourceMessage: true,
            showFirstLastPageButtons: true,
            showTitle: false,
            toolbar: true,
          }}
          columns={[
            {
              field: 'MobilityProviderNumber',
              render: rowData => <FirstColumn booking={rowData} />,
              // XXX fixed width to approximately match nested table in detail panel
              cellStyle: {
                width: 300,
              },
            },
            {
              field: 'bookingNumber',
              render: rowData => renderTableColumn(rowData, 0),
            },
            {
              field: 'vehicleInformation', // ticket number, bike number or vehicle number
              render: rowData => renderTableColumn(rowData, 1),
            },
            {
              field: 'bookingStart', // reservation start (nextbike), booking start (kvv, stadtmobil)
              render: rowData => renderTableColumn(rowData, 2),
            },
            {
              field: 'column-2', // booking start (nextbike), booking end (kvv, stadtmobil)
              render: rowData => renderTableColumn(rowData, 3),
            },
            {
              field: 'column-3', // station, booking end (nextbike), station (stadtmobil)
              render: rowData => renderTableColumn(rowData, 4),
            },
            {
              field: 'column-4', // rental state (nextbike, stadtmobil), starting zone (kvv)
              render: rowData => renderTableColumn(rowData, 5),
            },
            {
              field: 'Amount',
              render: rowData => renderTableColumn(rowData, 6),
            },
          ]}
          data={query => {
            return new Promise((resolve, reject) => {
              return _debouncedSearchFunction(
                initClient,
                userId,
                filter,
                tabFilter,
                query,
                resolve)
            })
          }}
          actions={[
            {
              icon: 'refundButton',
              tooltip: 'Erstatten',
              onClick: refundBookingItemCallback,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

BookingTable.propTypes = {
  refundBookingItem: PropTypes.func.isRequired,
}

const FirstColumnCell = ({ mobilityProviderNumber, caption, info }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container alignItems='center' wrap='nowrap'>
        {/* an avatar with a ticket icon for consistent spacing with main column */}
        <Grid item>
          <Avatar
            classes={{ root: classes.avatar }}
            style={{
              backgroundColor: getBookingProviderColor(mobilityProviderNumber),
            }}
          >
            <Icon
              className={getBookingProviderIcon(mobilityProviderNumber)}
            />
          </Avatar>
        </Grid>
        <Grid item className={classes.pl1}>
          <Typography variant='body1' noWrap>{caption}</Typography>
          <Typography variant='body2' noWrap>{info}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

FirstColumnCell.propTypes = {
  mobilityProviderNumber: PropTypes.string.isRequired,
  caption: PropTypes.string,
  info: PropTypes.string,
}

/**
 * Renders the first cell in any row, that has only one booking item (either for rows with one booking item or for children of ones with many booking items)
 * @param bookingItem
 * @returns {*}
 */
const FirstColumnOneBookingItemCell = ({ bookingItem }) => {
  let caption = ''
  let info = ''
  const mobilityProviderNumber = bookingItem.MobilityProviderNumber
  switch (mobilityProviderNumber) {
    case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID): {
      if (typeof bookingItem.ProductName !== 'undefined') {
        if (['9-Euro-Ticket', 'Fahrradkarte', '1. Klasse Zuschlag'].includes(bookingItem.ProductName)) {
          caption = bookingItem.ProductName
          info = null
        } else {
          const [productTitle, ...other] = bookingItem.ProductName.split(' ')
          caption = productTitle || 'KVV Ticket'
          info = other.join(' ') || 'nicht verfügbar'
        }
      }
      break
    }
    case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
      if (typeof bookingItem.ProductName !== 'undefined') {
        if (['badisch24', 'Fahrradkarte'].includes(bookingItem.ProductName)) {
          caption = bookingItem.ProductName
          info = null
        } else {
          const [productTitle, ...other] = bookingItem.ProductName.split(' ')
          caption = productTitle || 'TGO Ticket'
          info = other.join(' ') || 'nicht verfügbar'
        }
      }
      break
    }
    case getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID): {
      caption = getDisplayNameForMobilityProvider(mobilityProviderNumber)
      info = NEXTBIKE_BIKE_TYPES[bookingItem.BikeType] || 'nicht verfügbar'
      break
    }
    case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
      // Children use Product Name, Normal ones use default
      // Todo: not sure with one is right
      caption = getDisplayNameForMobilityProvider(mobilityProviderNumber)
      info = bookingItem.ProductName || 'nicht verfügbar'
      break
    }
    case getBookingProvider(BOOKING_PROVIDER_STADTMOBIL, CLIENT_ID):
    // fallthrough, using default
    case getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID):
    // fallthrough, using default
    default: {
      caption = getDisplayNameForMobilityProvider(mobilityProviderNumber)
      info = bookingItem.FeatureName || 'nicht verfügbar'
    }
  }
  return <FirstColumnCell mobilityProviderNumber={mobilityProviderNumber} caption={caption} info={info} />
}

FirstColumnOneBookingItemCell.propTypes = {
  bookingItem: PropTypes.object,
}

const FirstColumnManyBookingItemsCell = ({ booking }) => {
  const mobilityProviderNumber = booking.BookingItems[0].MobilityProviderNumber
  const caption = booking.BookingItems.length + ' Tickets'
  const info = Array.from(
    new Set(
      booking.BookingItems.map(a => getDisplayNameForMobilityProvider(a.MobilityProviderNumber))
    )
  ).join(', ')

  return <FirstColumnCell mobilityProviderNumber={mobilityProviderNumber} caption={caption} info={info} />
}

FirstColumnManyBookingItemsCell.propTypes = {
  booking: PropTypes.object,
}

const FirstColumn = ({ booking }) => {
  const hasSingleBookingItem = booking.BookingItems.length === 1

  if (!hasSingleBookingItem) {
    return <FirstColumnManyBookingItemsCell booking={booking} />
  }

  const bookingItem = booking.BookingItems[0]
  return <FirstColumnOneBookingItemCell bookingItem={bookingItem} />
}

FirstColumn.propTypes = {
  booking: PropTypes.object,
}

const MobilityProviderTabs = ({
  mobilityProviders,
  onChange,
  value,
}) => {
  const classes = useStyles()

  return (
    <Tabs
      className={classes.tabs}
      indicatorColor='primary'
      onChange={onChange}
      value={value}
    >
      <Tab value={null} label='Alle' />
      {
        mobilityProviders.map((provider) => {
          if (CLIENT_ID === 'ortenaumobil') {
            switch (provider.MobilityProviderName) {
              case 'nextbike (Offenburg)':
                return <Tab value={getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID)} label='nextBike' key={provider.MobilityProviderName} />
              case 'KVV':
                return <Tab value={getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID)} label={provider.MobilityProviderName} key={provider.MobilityProviderName} />
              case 'TGO':
                return <Tab value={getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)} label={provider.MobilityProviderName} key={provider.MobilityProviderName} />
                // fallthrough
              default:
                return null
            }
          } else {
            switch (provider.MobilityProviderName) {
              // XXX display ioki as myshuttle
              // TODO introducing i18next would allow to solve this with context
              case 'Ioki':
                return <Tab value={getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID)} label='MyShuttle' key={provider.MobilityProviderName} />
              case 'TGO':
                return <Tab value={getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)} label={provider.MobilityProviderName} key={provider.MobilityProviderName} />
              case 'KVV':
                // fallthrough
              case 'NextBike':
                // fallthrough
              case 'Voi':
                // fallthrough
              case 'Stadtmobil':
                // fallthrough
              default:
                return <Tab value={provider.MobilityProviderName} label={provider.MobilityProviderName} key={provider.MobilityProviderName} />
            }
          }
        })
      }
    </Tabs>
  )
}

MobilityProviderTabs.propTypes = {
  mobilityProviders: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

MobilityProviderTabs.defaultProps = {
  mobilityProviders: [],
}

const RefundAction = (props) => {
  const {
    action,
    data: {
      BookingItems,
      BookingID,
    },
  } = props

  const classes = useStyles()
  const [open, setRefundDialogOpen] = useState(false)
  const [refundCommentDialogOpen, setRefundCommentDialogOpen] = useState(false)

  // this prevents button showing up in main table row for bookings with multiple items
  if (BookingItems?.length > 1 && props.index === undefined) {
    return null
  }
  // this ensures that button still works in main table row for bookings with exactly one item
  const { index = 0 } = props
  // Refund is only possible for KVV, TGO & Voi bookings
  if ([getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID), getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID), getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID)].includes(BookingItems[index].MobilityProviderNumber)) {
    const hasBeenRefunded = Boolean(BookingItems[index].RefundedBookingItemId)
    const isRefundable = Boolean((!(BookingItems[index].BookingType === BOOKING_TYPE_CANCELED || BookingItems[index].BookingType === BOOKING_TYPE_FAILED)) && (!BookingItems[index].RefundedBookingItemId && BookingItems[index].SaleType === BOOKING_SALE_TYPE_SALE))
    const isRefunded = Boolean(BookingItems[index].BookingType === BOOKING_TYPE_CLEARED && BookingItems[index].Amount === 0 && !((BookingItems[index].Payments.find(p => p.PaymentState === BOOKING_SALE_TYPE_VOIDED))))
    const isVoided = Boolean(BookingItems[index].Payments.find(p => p.PaymentState === BOOKING_SALE_TYPE_VOIDED))

    if (hasBeenRefunded) {
      return (
        <Grid className={classes.actionColumnWidth}>
          <RequestButton
            color='primary'
            className={classes.mr1}
            disabled
            fullWidth
            variants='text'
          >
            Erstattet
          </RequestButton>
        </Grid>
      )
    } else if (isRefunded) {
      return (
        <Grid container alignItems='center' wrap='nowrap' className={classes.actionColumnWidth}>
          <Grid item className={classes.mr1}>
            <Typography
              variant='caption'
              style={{ color: '#00000099' }}
            >
              Erstattet am
            </Typography>
            <Typography
              variant='body2'
            >
              {formatTime(BookingItems[index].Payments.find(p => p.PaymentType === BOOKING_SALE_TYPE_REFUND)?.ExecutionTime)}
            </Typography>
          </Grid>
          <Grid>
            {BookingItems[index].Payments.find(p => p.PaymentType === BOOKING_SALE_TYPE_REFUND)?.BookingItemPaymentId &&
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setRefundCommentDialogOpen(true)
                }}
              >
                <Chat />
              </IconButton>}
            <RefundCommentDialog
              commentId={BookingItems[index].Payments.find(p => p.PaymentType === BOOKING_SALE_TYPE_REFUND)?.BookingItemPaymentId}
              onClose={() => { setRefundCommentDialogOpen(false) }}
              open={refundCommentDialogOpen}
            />
          </Grid>
        </Grid>
      )
    } else if (isVoided) {
      return null
    } else if (isRefundable) {
      return (
        <Grid className={classes.actionColumnWidth}>
          <RequestButton
            color='primary'
            className={classes.mr1}
            fullWidth
            variant='outlined'
            onClick={() => { setRefundDialogOpen(true) }}
          >
            Erstatten
          </RequestButton>
          <RefundDialog
            open={open}
            onClose={() => { setRefundDialogOpen(false) }}
            isCommentAllowed
            refundingTicket={(event, refundComment) => action.onClick(event, BookingItems[index], refundComment)?.then(() => { setRefundDialogOpen(false) })}
            bookingID={BookingID}
            ticketID={BookingItems[index].BookingItemID}
            mobilityProvider={BookingItems[index].MobilityProviderNumber}
          />
        </Grid>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}

RefundAction.propTypes = {
  data: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 50,
    height: 50,
    color: 'white',
  },
  caption: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  pl1: {
    paddingLeft: theme.spacing(1),
  },
  tabs: {
    margin: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    // this pushes filter and search to the right
    flexGrow: 1,
  },
  table: {
    width: '100%',
  },
  actionColumnWidth: {
    width: '150px',
  },
  displayNone: {
    display: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}))

const _searchFunction = (
  initClient,
  userId,
  filter,
  tabFilter,
  query,
  resolve) => {
  return initClient.customerBookings.getCustomerBookings({
    customerAccountId: userId,
    sort: 'BookingDate:desc',
    MobilityProvider: tabFilter,
    BookingItemPaymentType: filter.find(f => f.parameter === 'BookingItemPaymentType')?.value,
    BookingID: query.search,
    ProductType: 'none',
    skip: query.page * query.pageSize,
    take: query.pageSize,
  })
    .then(response => {
      const { Result: bookings } = response.obj
      // This resolve with given structure is needed by material-table
      resolve({
        data: bookings || [],
        page: query.page,
        totalCount: response.obj.TotalCount,
      })

      return response
    },
    error => {
      logger.error('Error while fetching data', error)
    })
}

const _debouncedSearchFunction = AwesomeDebouncePromise(_searchFunction, 500)

export default BookingTable
