// Mobility providers
export const LOGPAY_STATE_ACTIVE = 'ACTIVE'
export const MOBILITY_PROVIDER_KVV = 'KVV'
export const MOBILITY_PROVIDER_STATE_ACTIVE = 'Active'
export const MOBILITY_PROVIDER_STADTMOBIL = 'Stadtmobil'

// MobilityProviderNumber as defined by INIT
// checking which application is running.
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const BOOKING_PROVIDER_KVV = 'KVV'
export const BOOKING_PROVIDER_TGO = 'TGO'
export const BOOKING_PROVIDER_NEXTBIKE = 'NextBike'
export const BOOKING_PROVIDER_ZEO = 'Zeo'
// will be fixed in lsd, for now heres
export const BOOKING_PROVIDER_STADTMOBIL = 'StadtMobil'
export const BOOKING_PROVIDER_VOI = 'VOI'
export const BOOKING_PROVIDER_IOKI = 'IOKI'

// Nextbike bike types according to config version 1.2
export const NEXTBIKE_BIKE_TYPES = {
  0: 'Unbekannt',
  2: '28 Zoll - Tourenfahrrad',
  4: 'Comfort',
  5: 'Classic',
  6: 'E-Bike',
  17: 'E-Bike',
  19: 'E-Bike',
  28: 'Lastenrad zweispurig',
  29: 'Lastenrad einspurig',
  71: 'City-Bike',
  120: 'E-Bike',
  150: 'ECObike',
}

// Mobility provider numbers
export const MOBILITY_PROVIDER_NUMBER_KVV = 1
export const MOBILITY_PROVIDER_NUMBER_VOI = 4
export const MOBILITY_PROVIDER_NUMBER_TGO = 6
export const MOBILITY_PROVIDER_NUMBER_ZEO = 13

// INIT sale types
export const BOOKING_SALE_TYPE_REFUND = 'Refund'
export const BOOKING_SALE_TYPE_SALE = 'Sale'
export const BOOKING_SALE_TYPE_VOIDED = 'Voided'

// booking item states
export const BOOKING_TYPE_INACTIVE = 'Inactive'
export const BOOKING_TYPE_RESERVED = 'Reserved'
export const BOOKING_TYPE_ACTIVE = 'Active'
export const BOOKING_TYPE_CLEARED = 'Cleared'
export const BOOKING_TYPE_NONE = 'None'
export const BOOKING_TYPE_READY = 'Ready'
export const BOOKING_TYPE_CANCELED = 'Canceled'
export const BOOKING_TYPE_CREATED = 'Created'
export const BOOKING_TYPE_FAILED = 'Failed'
export const BOOKING_TYPE_WAITING = 'Waiting'
export const BOOKING_TYPE_PAUSED = 'Paused'
export const BOOKING_TYPE_FINISHED = 'Finished'
