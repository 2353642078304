import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@material-ui/core'

const DeleteUserConfirmation = ({ onClose, open, cancelUserAccount }) => {
  return (
    <>
      <Dialog
        maxWidth='xs'
        open={open}
      >
        <DialogTitle>Nutzer wirklich kündigen?</DialogTitle>
        <DialogContent>
          Diese Aktion hat das Löschen des Nutzerkontos zur Folge und kann nicht rückgängig gemacht werden.
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: 'center' }} xs={12}>
          <Button onClick={onClose} color='primary' variant='outlined' fullWidth xs={6}>
            Abbrechen
          </Button>
          <Button onClick={cancelUserAccount} color='primary' variant='contained' fullWidth xs={6}>
            Nutzer kündigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
DeleteUserConfirmation.propTypes = {
  /** Function to be thrown on closing the dialog **/
  onClose: PropTypes.func.isRequired,
  cancelUserAccount: PropTypes.func,
  /** Boolean to determine if Dialog is Open or not **/
  open: PropTypes.bool.isRequired,
}

export default DeleteUserConfirmation
