import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { OidcProvider } from '@axa-fr/react-oidc-context'

const configuration = {
  authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: `${window.origin}/authentication/callback`,
  scope: process.env.REACT_APP_IDENTITY_SCOPE,
  silent_redirect_uri: `${window.origin}/authentication/silent_callback`,
}

const AuthenticationProvider = ({
  children,
}) => {
  // copied and adapted from https://github.com/AxaFrance/react-oidc/blob/8a4785cc11ceedc17e4ddfe38b6b3bd05bb4aa0f/packages/react/src/App.tsx#L25-L28
  const onEvent = useCallback((configurationName, eventName, data) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(`oidc:${configurationName}:${eventName}`, data)
    }
  }, [])

  return (
    <OidcProvider
      configuration={configuration}
      onEvent={onEvent}
    >
      {children}
    </OidcProvider>
  )
}

AuthenticationProvider.propTypes = {
  children: PropTypes.any,
}

export default AuthenticationProvider
