import React, { useState, useCallback } from 'react'
import useInitClient from 'hooks/useInitClient'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import {
  Clear,
} from '@material-ui/icons'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

import RequestButton from 'components/RequestButton'

const SearchUserDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const initClient = useInitClient()
  const history = useHistory()
  const [inputValue, setInputValue] = useState(undefined)
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState(false)

  const getUser = useCallback(() => {
    setIsLoading(true)
    initClient.customerAccounts.getCustomerDetails({ customerAccountId: inputValue })
      .then(response => {
        setIsLoading(false)
        return history.push(`/userdetails/${inputValue}`)
      }).catch(() => {
        setIsLoading(false)
        setError(true)
        setInputValue('')
      })
  }, [initClient, inputValue, history])

  const clearSearch = () => setInputValue('')

  return (
    <Dialog
      maxWidth='xs'
      open={open}
    >
      <DialogTitle>
        <Typography variant='h4'>Nutzer über User&nbsp;ID aufrufen</Typography>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <Typography variant='body1' color='error' className={clsx(classes.mb2, classes.error)}>User&nbsp;ID wurde nicht gefunden.</Typography>
        ) : (
          <Typography variant='body1' className={classes.mb2}>
            Geben Sie die vollständige User&nbsp;ID des gesuchten Nutzers ein.
          </Typography>
        )}
        <TextField
          autoFocus
          className={error ? classes.error : classes.mb2}
          fullWidth
          error={error}
          label='User ID'
          onChange={(newValue) => setInputValue(newValue.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (error) { setError(false) }
              if (inputValue !== undefined) { getUser() }
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={clearSearch} edge='end'>
                <Clear />
              </IconButton>
            ),
          }}
          placeholder='Bitte geben Sie eine User ID ein'
          type='number'
          value={inputValue}
          variant='standard'
        />
      </DialogContent>
      <Divider variant='middle' />
      <DialogActions>
        <Button onClick={onClose} variant='outlined' fullWidth color='primary'>
          Abbrechen
        </Button>
        <RequestButton
          color='primary'
          fullWidth
          isLoading={isLoading}
          variant='contained'
          onClick={() => {
            if (error) { setError(false) }
            if (inputValue !== undefined) { getUser() }
          }}
        >
          Nutzer anzeigen
        </RequestButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.raumo.palette.error,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}))

SearchUserDialog.propTypes = {
  /** Function close Dialog **/
  onClose: PropTypes.func.isRequired,
  /** Function to be thrown on opening Dialog **/
  open: PropTypes.bool.isRequired,
}

export default SearchUserDialog
