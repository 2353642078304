
import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  makeStyles,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Formik, Form } from 'formik'

/**
 *  a dialog to comment on a user
 */

const UserCommentDialog = ({
  onCancel,
  onSubmit,
  open,
  comment,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    onCancel()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='title'
      maxWidth='sm'
      fullWidth
    >
      <Formik
        initialValues={{
          Comment: comment,
        }}
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        {({
          handleChange,
          values,
        }) => {
          return (
            <Form>
              <DialogTitle id='title'>
                <Typography component='div' variant='h4'>
                  Kommentar verfassen
                </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  multiline
                  id='Comment'
                  onChange={handleChange}
                  minRows={4}
                  variant='outlined'
                  value={values.Comment}
                  placeholder='Kommentar zum Nutzer hinterlassen...'
                  inputProps={{ maxLength: 2000 }}
                />
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  color='primary'
                  fullWidth
                  onClick={() => {
                    handleClose()
                  }}
                  variant='outlined'
                  xs={6}
                  className={classes.m2}
                >
                  Abbrechen
                </Button>
                <Button
                  color='primary'
                  fullWidth
                  type='submit'
                  variant='contained'
                  xs={6}
                  className={classes.m2}
                >
                  Ändern
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  m2: {
    margin: theme.spacing(2),
  },
}))

UserCommentDialog.propTypes = {
  /** Comment value from user object which is displayed */
  comment: PropTypes.string,
  /** callback when dialog is cancelled */
  onCancel: PropTypes.func,
  /** controls whether the dialog is shown */
  open: PropTypes.bool,
  /** callback when form in dialog is submitted, receives form data as argument */
  onSubmit: PropTypes.func,
}
export default UserCommentDialog
