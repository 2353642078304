import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import logger from 'util/logger'

import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  Grid,
  Divider,
  makeStyles,
  withStyles,
  Link,
} from '@material-ui/core'
import useInitClient from 'hooks/useInitClient'
import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component='div' variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const PaymentMethodComponent = props => {
  const { paymentMethodId, isFavorite, headline, label } = props
  const classes = useStyles()

  return (
    <Grid key={paymentMethodId} container item direction='column'>
      <Grid container item direction='row' justify='flex-start' wrap='nowrap'>
        <Grid item>
          <Typography color='textSecondary' variant='body2'>{headline}</Typography>
        </Grid>
        {isFavorite === true
          ? (
            <Grid item xs>
              <Typography color='primary' className={['icon-topoffer', classes.starIcon]} />
            </Grid>
          ) : null}
      </Grid>
      <Grid item className={classes.mb2}>
        <Typography color='textSecondary' variant='caption'>{label}</Typography>
      </Grid>
    </Grid>
  )
}

PaymentMethodComponent.propTypes = {
  paymentMethodId: PropTypes.string.isRequired,
  isFavorite: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

/**
 *  this dialog manages its internal "open" state but has an imperative handle to enable parent components to open it
 *  see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
 */
const AccountRelationInfoDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const initClient = useInitClient()
  const [user, setUser] = useState(null)
  const [userPaymentAccount, setUserPaymentAccount] = useState(null)
  const [isPaymentAccountLoading, setPaymentAccountLoading] = useState(false)

  const { userId } = useParams()

  const {
    MobilityProviders,
  } = user || {}

  const {
    PaymentsMethods,
    AccountBalance,
  } = userPaymentAccount || {}

  const {
    name,
  } = props

  useEffect(() => {
    initClient.customerAccounts.getCustomerDetails({ customerAccountId: userId })
      .then(response => {
        setUser(response.obj)
        return response.obj
      })
      .catch(error => {
        logger.error('Error while fetching data', error)
      })
  }, [initClient, userId])
  useEffect(() => {
    setPaymentAccountLoading(true)
    initClient.customerPaymentAccount.getCustomerPaymentAccount({ customerAccountId: userId })
      .then(response => {
        setUserPaymentAccount(response.obj)
        return response.obj
      })
      .catch(error => {
        logger.error('Error while fetching data', error)
      })
      .finally(() => {
        setPaymentAccountLoading(false)
      })
  }, [initClient, userId])

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true)
    },
  }))

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='title' open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='title' onClose={handleClose}>
        <Typography component='div' variant='h4' className={classes.mb2}>{name} Details</Typography>
        <Typography variant='subtitle2' color='textSecondary'>Hier finden Sie alle {name} Details, die zu diesem Nutzer vorliegen.</Typography>
      </DialogTitle>
      <DialogContent>
        {isPaymentAccountLoading
          ? (
            <div className={`${classes.centerSpinner} fullHeight`}>
              <CircularProgress color='primary' />
            </div>
          ) : (
            <Grid container item className={classes.text}>
              {name === 'Stadtmobil'
                ? (
                  <Grid item xs>
                    <Typography component='div' color='textPrimary' variant='subtitle2'>Kundennummer</Typography>
                    <Grid container item className={classes.mb2}>
                      {MobilityProviders && MobilityProviders.map(mp => {
                        if (mp.MobilityProviderName === 'Stadtmobil') {
                          return (
                            <Grid>
                              <Typography color='textSecondary' variant='body2'>{mp.ExternalCustomerReference}</Typography>
                            </Grid>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Grid>
                    <Grid container item>
                      <Typography component='div' color='textPrimary' variant='subtitle2'>Support Kontakt</Typography>
                      <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                          <Typography color='textSecondary' variant='body2'>Telefon</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography color='textSecondary' variant='body2'>+49 (0) 721 9119110</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                          <Typography color='textSecondary' variant='body2'>E-Mail</Typography>
                        </Grid>
                        <Grid item xs>
                          <Link className={classes.link} variant='body2' href='mailto:info@karlsruhe.stadtmobil.de'>info@karlsruhe.stadtmobil.de</Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid item xs>
                    <Typography component='div' color='textPrimary' variant='subtitle2' className={classes.mb1}>Hinterlegte Zahldaten</Typography>
                    <Grid container item className={classes.mb2}>
                      <Grid container item direction='column'>
                        {PaymentsMethods && PaymentsMethods.map((pm, ind) => {
                          if (pm.Description === 'POSTCC') {
                            return (
                              <PaymentMethodComponent
                                key={pm?.PaymentMethodId + ind}
                                paymentMethodId={pm?.PaymentMethodId}
                                isFavorite={pm.IsFavorite}
                                headline='Kreditkarte'
                                label={pm.Label}
                              />
                            )
                          } else if (pm.Description === 'POSTDD') {
                            return (
                              <PaymentMethodComponent
                                key={pm?.PaymentMethodId + `POSTDD${ind}`}
                                paymentMethodId={pm?.PaymentMethodId}
                                isFavorite={pm.IsFavorite}
                                headline='SEPA Mandat'
                                label={pm.Label}
                              />
                            )
                          } else if (pm.Description === 'POSTPP') {
                            return (
                              <PaymentMethodComponent
                                key={pm?.PaymentMethodId + `POSTPP${ind}`}
                                paymentMethodId={pm?.PaymentMethodId}
                                isFavorite={pm.IsFavorite}
                                headline='Paypal'
                                label={pm.Label}
                              />
                            )
                          } else if (pm.Description === 'PREPAY') {
                            const label = 'Guthaben: ' + AccountBalance.Value?.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            })

                            return (
                              <PaymentMethodComponent
                                key={pm?.PaymentMethodId + `PREPAY${ind}`}
                                paymentMethodId={pm?.PaymentMethodId}
                                isFavorite={pm.IsFavorite}
                                headline='Prepay'
                                label={label}
                              />
                            )
                          } else {
                            return null
                          }
                        })}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Typography component='div' color='textPrimary' variant='subtitle2' className={classes.mb1}>Support Kontakt</Typography>
                      <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                          <Typography color='textSecondary' variant='body2'>Telefon</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography color='textSecondary' variant='body2'>+49 (0) 6196 8012 702</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                          <Typography color='textSecondary' variant='body2'>E-Mail</Typography>
                        </Grid>
                        <Grid item xs>
                          <Link className={classes.link} variant='body2' href='mailto:kundencenter@logpay.de'>kundencenter@logpay.de</Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          )}

      </DialogContent>
      <Divider variant='middle' />
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const useStyles = makeStyles(theme => ({
  centerIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
  },
  text: {
    justifyContent: 'space-between',
    alignContent: 'space-evenly',
    wrap: 'nowrap',
  },
  link: {
    underline: 'none',
    color: 'primary',
  },
  starIcon: {
    lineHeight: 1.2,
    marginLeft: theme.spacing(1),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

AccountRelationInfoDialog.propTypes = {
  name: PropTypes.string.isRequired,
}
export default AccountRelationInfoDialog
