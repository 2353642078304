import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Divider,
  makeStyles,
  withStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import useInitClient from 'hooks/useInitClient'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component='div' variant='h6'>{children}</Typography>
      {onClose &&
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(),
    padding: theme.spacing(),
  },
}))(MuiDialogActions)

const RefundCommentDialog = ({ onClose, open, commentId }) => {
  const classes = useStyles()

  const [comment, setComment] = useState()

  const initClient = useInitClient()
  useEffect(() => {
    // only load comment when dialog is open and init client is ready
    if (open && typeof initClient.bookingItemPayments?.getBookingItemPaymentComment === 'function') {
      initClient.bookingItemPayments.getBookingItemPaymentComment({
        bookingItemPaymentId: commentId,
      })
        .then(response => {
          setComment(response.obj.Comment)
        })
    }
  }, [commentId, initClient.bookingItemPayments, open])

  return (
    <Dialog onClose={onClose} aria-labelledby='title' open={open} fullWidth>
      <DialogTitle id='title' onClose={onClose}>
        <Typography component='div' variant='h4' className={classes.mb2}>Erstattungsgrund</Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          {comment ? '"' + comment + '"' : 'keine Angaben'}
        </Typography>
      </DialogTitle>
      <Divider variant='middle' />
      <DialogActions>
        <Button autoFocus onClick={onClose} variant='contained' color='primary'>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))
RefundCommentDialog.propTypes = {
  /** ID to load comment from API */
  commentId: PropTypes.string,
  /** Function to close dialog */
  onClose: PropTypes.func,
  /** controls whether dialog is shown or not */
  open: PropTypes.bool,
}

export default RefundCommentDialog
