import React from 'react'
import PropTypes from 'prop-types'

import {
  Input,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'

const UserPersonDataCard = props => {
  const {
    salutation,
    firstName,
    lastName,
    address,
    dateOfBirth,
  } = props

  // split to catch possible null value on address
  const { AddressLine1, PostalCode, City, Country } = address || { AddressLine1: '', PostalCode: '', City: '', Country: '' }

  return (
    <Card square className='fullHeight'>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mb={1}>
              <Typography variant='caption'>
                Anrede
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={salutation} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='caption'>
                Vorname
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={firstName} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='caption'>
                Name
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={lastName} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={0}>
              <Typography variant='caption'>
                Geburtsdatum
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={Intl.DateTimeFormat('de-DE', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(new Date(dateOfBirth))} fullWidth readOnly />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={1}>
              <Typography variant='caption'>
                Straße, Hausnummer
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={AddressLine1 || 'keine Adresse'} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='caption'>
                PLZ
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={PostalCode || 'keine PLZ'} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='caption'>
                Stadt
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={City || 'keine Stadt'} fullWidth readOnly />
              </Typography>
            </Box>
            <Box mb={0}>
              <Typography variant='caption'>
                Land
              </Typography>
              <Typography component='div' variant='subtitle1'>
                <Input value={Country || 'kein Land'} fullWidth readOnly />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

UserPersonDataCard.propTypes = {
  salutation: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  address: PropTypes.shape({
    AddressLine1: PropTypes.string.isRequired,
    PostalCode: PropTypes.string.isRequired,
    City: PropTypes.string.isRequired,
    Country: PropTypes.string.isRequired,
  }),
}

export default UserPersonDataCard
